













































































































































































































































































































































import { createComponent, ref } from '@vue/composition-api'
import useFormatService from '@/composition/FormatService'
import UserInfo from '@/components/shared/UserInfo.vue'
import axios from '@/plugins/axios'
import notifyService from '@/services/notify-service'
import { OrderStatus } from '@/types/constant'
import { OrderDetail } from '@/types/order'

export default createComponent({
  name: 'Collaborate',
  components: {
    UserInfo
  },
  setup(props, ctx) {
    const { root } = ctx
    const loading = ref(true)
    const userActivities = ref([])
    const { formatDateWeek } = useFormatService()

    async function fetchUserActivitiesInfo() {
      try {
        loading.value = true
        const result = await axios.get(`api/user-activities`)
        const data = result.data.data

        userActivities.value = data
          .filter(
            (userActivity: any) =>
              userActivity.activity.travel_groups.length >= 0
          )
          .map((userActivity: any) => {
            let activity = {
              ...userActivity.activity,
              travel_groups: userActivity.activity.travel_groups
                .filter((travelGroup: any) =>
                  canShowGroup(userActivity, travelGroup)
                )
                .map((group: any) => {
                  let remainder_quantity = 0

                  if (group.spot_questions.length > 0) {
                    remainder_quantity = group.spot_questions[0].spot_options.reduce(
                      (sum: number, o: any) => sum + o.remainder_quantity,
                      0
                    )
                  }

                  return {
                    ...group,
                    is_full: remainder_quantity === 0,
                    remainder_quantity
                  }
                })
            }

            let pay_quota = 0 // 自費名額(未上傳)
            let quota = 1 // 報名人數(未上傳)
            let quota_free = 1 // 免攜人數(未上傳)
            let free_count = 0 // 免攜剩餘數量(未上傳)
            let quota_pay = 0 // 自費人數(未上傳)

            for (const user_order of userActivity.user_orders) {
              // eslint-disable-next-line no-console
              console.log('user_order.status', user_order.status)
              if (user_order.status <= -1) {
                continue
              }

              const temp_details = user_order.temp_details.filter(
                (d: OrderDetail) => !d.mark_remove
              )

              // TODO 需要檢查是否是目前報名的團
              const max_quota = userActivity.max_quota // 最大報名人數
              const free_quota = userActivity.free_quota // 免攜名額(未上傳)
              pay_quota = max_quota - free_quota
              quota = temp_details.length
              quota_free = temp_details.filter((d: OrderDetail) => d.is_free)
                .length
              free_count = free_quota - quota_free
              quota_pay = quota - quota_free
            }

            // eslint-disable-next-line no-console
            console.log({
              max_quota: userActivity.max_quota,
              free_quota: userActivity.free_quota,
              pay_quota,
              quota,
              quota_free,
              free_count,
              quota_pay
            })

            return {
              ...userActivity,
              activity,
              pay_quota,
              quota,
              quota_free,
              free_count,
              quota_pay
            }
          })

        // eslint-disable-next-line no-console
        console.log(userActivities.value)
      } catch (e) {
        const message = e.message || '無法載入活動列表！請聯絡客服人員'
        // eslint-disable-next-line no-console
        console.error('fetchCollaborateInfo failed: ', message)
        notifyService.alert(message, 0, () => {
          root.$router.push({ name: 'login' }).catch(() => {})
        })
      } finally {
        loading.value = false
      }
    }

    function groupStatus(userActivity: any, travelGroup: any) {
      if (!travelGroup.is_registration_start) {
        return '尚未開放'
      }

      if (
        userActivity.completed_order &&
        userActivity.completed_order.group_code == travelGroup.code
      ) {
        const status = userActivity.completed_order.status

        if (status == OrderStatus.None) {
          return '未報名'
        } else if (status == OrderStatus.Specified) {
          return '未報名'
        } else if (status == OrderStatus.Opened) {
          return '未報名'
        } else if (status == OrderStatus.Edited) {
          return '未報名'
        } else {
          return '報名'
        }
      }

      if (travelGroup.is_full) {
        return '已滿團'
      }

      return ''
    }

    function groupClass(userActivity: any, travelGroup: any) {
      if (
        userActivity.completed_order &&
        userActivity.completed_order.group_code === travelGroup.code
      ) {
        return 'sign-up is-white'
      } else {
        if (!travelGroup.is_registration_opening) {
          return 'sing-up-disable is-white'
        } else if (!travelGroup.is_active && travelGroup.is_registration_end) {
          return 'sign-up-end is-white'
        } else {
          return 'is-white'
        }
      }
    }

    function canSignupGroup(userActivity: any, travelGroup: any) {
      if (!travelGroup.is_registration_opening) {
        return false
      }
      if (travelGroup.is_expired || travelGroup.is_full) {
        return false
      }

      return !userActivity.completed_order
    }

    function canModifyGroup(userActivity: any, travelGroup: any) {
      if (!travelGroup.is_registration_opening) {
        return false
      }
      if (!userActivity.completed_order) {
        return false
      }
      if (userActivity.completed_order.group_code !== travelGroup.code) {
        return false
      }

      return !userActivity.completed_order.is_modify_expired
    }

    function ModifyBtnText(userActivity: any, travelGroup: any) {
      const status = userActivity.completed_order.status

      if (status == OrderStatus.None) {
        return '報名'
      } else if (status == OrderStatus.Specified) {
        return '報名'
      } else if (status == OrderStatus.Opened) {
        return '報名'
      } else if (status == OrderStatus.Edited) {
        return '報名'
      } else {
        return '修改'
      }
    }

    function canShowGroup(userActivity: any, travelGroup: any) {
      if (
        userActivity.completed_order &&
        userActivity.completed_order.group_code === travelGroup.code
      ) {
        return true
      }

      if (userActivity.activity.only_show_specify_group) {
        return false
      }

      return !travelGroup.is_expired
    }

    function canViewGroupDetail(userActivity: any, travelGroup: any) {
      if (!travelGroup.is_registration_start) {
        return false
      }

      if (
        userActivity.completed_order &&
        userActivity.completed_order.group_code === travelGroup.code
      ) {
        return true
      }

      if (userActivity.activity.only_view_specify_group) {
        return false
      }

      return !travelGroup.is_expired
    }

    fetchUserActivitiesInfo()

    return {
      loading,
      userActivities,
      groupClass,
      groupStatus,
      canSignupGroup,
      canModifyGroup,
      ModifyBtnText,
      canViewGroupDetail,
      formatDateWeek
    }
  }
})
