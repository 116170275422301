import { ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { TravelGroup } from '@/types/order'
import useImageService from '@/composition/ImageService'

export default function useTravelGroupService() {
  const { collaborateActivityImageUrl, productImageUrl } = useImageService()

  const groupLoading = ref(true)
  const groupResult = ref(false)
  const group = ref<TravelGroup>({
    code: 'XXXXXX00000000X',
    name: '|',
    departure_date: null,
    cover: productImageUrl(''),
    activityCover: collaborateActivityImageUrl(''),
    activity: {}
  })

  async function fetchTravelGroupInfo(code: string) {
    try {
      groupLoading.value = true
      const result = await axios.get(`api/travels/${code}`)
      const data = result.data.data
      group.value = {
        ...data,
        departure_date: data.departure_date && new Date(data.departure_date),
        cover: productImageUrl(data.picture4),
        activityCover: collaborateActivityImageUrl(data.activity.cover)
      }
      groupResult.value = true
    } catch (e) {
      groupResult.value = false
      // eslint-disable-next-line no-console
      console.error('fetchTravelGroupInfo failed: ', e.message)
    } finally {
      groupLoading.value = false
    }
  }

  return {
    groupLoading,
    groupResult,
    group,
    fetchTravelGroupInfo
  }
}
