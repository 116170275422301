




























import { createComponent, ref, watch } from '@vue/composition-api'

export default createComponent({
  name: 'FRadios',
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: [String, Number]
    },
    options: {
      type: Array,
      default: []
    },
    showHighlight: {
      type: Boolean,
      default: true
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const innerValue = ref<string | number>(null)

    function setInnerValue() {
      if (props.value) {
        innerValue.value = props.value
      } else {
        innerValue.value = null
      }
    }

    watch(() => props.value, setInnerValue)
    watch(innerValue, newVal => {
      context.emit('input', newVal)
    })

    setInnerValue()

    function getFieldStyle(errors: string[]) {
      if (!props.showHighlight) {
        return null
      }

      return errors[0] ? 'is-danger' : null
    }

    function getErrorMessage(errors: string[]) {
      return props.showErrors ? errors : null
    }

    return {
      innerValue,
      getFieldStyle,
      getErrorMessage
    }
  }
})
