






















































































































































































































import Vue from 'vue'
import { createComponent, ref, computed } from '@vue/composition-api'
import {
  TravelGroup,
  Order as OrderType,
  OrderDetail as OrderDetailType
} from '@/types/order'
import notifyService from '@/services/notify-service'
import useConstantService from '@/composition/ConstantService'
import OrderDetail from '@/components/order/OrderDetail.vue'
import OrderDetailForm from '@/components/order/OrderDetailForm.vue'
import useOrderDetailService from '@/composition/OrderDetailService'
import { OrderStatus } from '@/types/constant'

export default createComponent({
  name: 'OrderDetails',
  components: {
    OrderDetail,
    OrderDetailForm
  },
  props: {
    group: {
      type: Object as () => TravelGroup,
      required: true
    },
    order: {
      type: Object as () => OrderType,
      required: true
    },
    showTutorialPassengerSignup: {
      type: Boolean,
      required: false
    }
  },
  setup(props, ctx) {
    const { root, emit } = ctx
    const { group, order } = props
    const { navbar } = useConstantService()
    const { deleteDetail } = useOrderDetailService()

    const openedDetails = ref<Array<number>>([])
    const showAddDetail = computed(() => {
      return order.user_activity.max_quota > 1
    })
    const canAddDetail = computed(() => {
      return (
        order.user_activity.max_quota > order.quota &&
        openedDetails.value.length === 0 &&
        !group.is_expired
      )
    })
    const canDeleteDetail = computed(() => !group.is_expired)
    const isEditing = computed(() => openedDetails.value.length > 0)
    const isAdding = computed(() => openedDetails.value.includes(-1))

    const canCancel = computed(() => {
      return (
        group.activity!.can_cancel_order &&
        order.temp_details.length === 1 &&
        !group.is_expired &&
        order.status >= OrderStatus.Completed
      )
    })

    const detailTemplate = ref<OrderDetailType>({})

    const isNextStepModalActive = ref(false)

    function onNewDetail() {
      if (!openedDetails.value.includes(-1)) {
        openedDetails.value.push(-1)
        emit('edit-detail', true)

        detailTemplate.value = {
          id: -1,
          is_self: false,
          order_no: null,
          formal_detail_id: null,
          type: null,
          type_message: null,
          status: null,
          status_message: null,
          mark_remove: false,
          is_modified: false,
          is_free: order.free_count > 0,
          name: null,
          nationality: 1,
          identity_number: null,
          gender: null,
          english_name: null,
          email: null,
          emergency_contact: null,
          emergency_phone: null,
          relationship: '其他',
          date_of_birth: null,
          age: -1,
          age_type: 0,
          bed_type: 0,
          traveler_type: null,
          passport_number: null,
          passport_expire: null,
          passport_image: null,
          mobile_phone: null,
          tel: null,
          home_address: null,
          remark: null,
          common_answers: [],
          spot_answers: []
        }

        Vue.nextTick(() => {
          root.$scrollTo('#order-detail-form-new', { offset: -navbar.height })
        })
      }
    }

    function onEditDetail(id: number) {
      if (!openedDetails.value.includes(id)) {
        openedDetails.value.push(id)
        emit('edit-detail', true)
      }
    }

    function onCloseDetail(id: number) {
      openedDetails.value = openedDetails.value.filter(item => item != id)
      if (openedDetails.value.length === 0) {
        emit('edit-detail', false)
      }
    }

    function onCreatedDetail(id: number, orderData: OrderType) {
      openedDetails.value = openedDetails.value.filter(item => item != -1)
      emit('order-modified', orderData)

      if (openedDetails.value.length === 0) {
        emit('edit-detail', false)
      }
    }

    const updatedOrderDetailId = ref<number>(null)
    const updatedOrderDetail = ref<OrderDetailType>(null)
    const updatedOrderData = ref<OrderType>(null)

    function onUpdatedDetail(id: number, orderData: OrderType) {
      isNextStepModalActive.value = true
      updatedOrderDetailId.value = id
      updatedOrderData.value = orderData

      openedDetails.value = openedDetails.value.filter(item => item != id)
      if (openedDetails.value.length === 0) {
        emit('edit-detail', false)
      }

      if (id !== -1) {
        const detail = orderData.temp_details.find(d => d.id === id)
        updatedOrderDetail.value = detail || null
      } else {
        const detail = orderData.temp_details[orderData.temp_details.length - 1]
        updatedOrderDetail.value = detail || null
        updatedOrderDetailId.value = detail ? detail.id : null
      }
    }

    function closeNextStepModal() {
      isNextStepModalActive.value = false
      emit('order-modified', updatedOrderData.value)
    }

    function fillDetailSignup() {
      if (!updatedOrderDetailId.value) {
        return
      }

      root.$router.push({
        name: 'order-detail-signup',
        params: { id: updatedOrderDetailId.value.toString() }
      })
    }

    function addNewDetail() {
      isNextStepModalActive.value = false
      emit('order-modified', updatedOrderData.value, false)

      onNewDetail()
    }

    function toGroupConfirmPage() {
      root.$router.push({
        name: 'travel-group-confirm',
        params: { code: order.group_code }
      })
    }

    async function onDeleteDetail(detail: OrderDetailType) {
      const loadingComponent = root.$buefy.loading.open({ container: null })

      const result = await deleteDetail(detail.id)

      loadingComponent.close()

      if (result.success) {
        notifyService.confirm({
          title: '旅客基本資料已刪除',
          message: '是否立即送出',
          confirmText: '立即報名',
          cancelText: '稍後報名',
          onConfirm: () => {
            root.$router.push({
              name: 'travel-group-confirm',
              params: { code: order.group_code }
            })
          },
          onCancel: () => {
            openedDetails.value = openedDetails.value.filter(
              item => item != detail.id
            )
            emit('order-modified', result.data)

            if (openedDetails.value.length === 0) {
              emit('edit-detail', false)
            }
          }
        })
      } else {
        notifyService.alert(result.message)
      }
    }

    async function onDeleteOrder() {
      emit('delete-order')
    }

    return {
      showAddDetail,
      canAddDetail,
      canDeleteDetail,
      openedDetails,
      isEditing,
      isAdding,
      canCancel,
      detailTemplate,
      isNextStepModalActive,
      onNewDetail,
      onEditDetail,
      onCloseDetail,
      onCreatedDetail,
      onUpdatedDetail,
      onDeleteDetail,
      onDeleteOrder,

      updatedOrderDetail,
      closeNextStepModal,
      fillDetailSignup,
      addNewDetail,
      toGroupConfirmPage
    }
  }
})
