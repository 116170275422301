








































































































import { createComponent, computed, ref, watch } from '@vue/composition-api'
import { OrderDetail } from '@/types/order'

export default createComponent({
  name: 'OrderDetailInfo',
  props: {
    isLastPassenger: {
      type: Boolean,
      default: false
    },
    showTutorialPassenger: {
      type: Boolean,
      default: false
    },
    showTutorialPassengerSignup: {
      type: Boolean,
      default: true
    },
    canDeleteDetail: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    data: {
      type: Object as () => OrderDetail,
      required: true
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const detail = ref<OrderDetail>({ ...props.data })
    const isSelf = computed(() => props.index === 0)

    watch(
      () => props.data,
      () => {
        detail.value = { ...props.data }
      }
    )

    function onEdit() {
      emit('edit')
    }

    function onSignup() {
      emit('signup')
    }

    function onDelete() {
      emit('delete')
    }

    return {
      isSelf,
      detail,
      onEdit,
      onSignup,
      onDelete
    }
  }
})
