









































































import { createComponent, ref, computed } from '@vue/composition-api'
import useConstantService from '@/composition/ConstantService'

export default createComponent({
  name: 'Navbar',
  setup(props, { root }) {
    const { navbar } = useConstantService()

    const headerStyle = ref({
      height: navbar.height + 'px'
    })

    const isLogin = computed(() => {
      return root.$auth.check()
    })
    const openInfo = ref(false)
    const userName = computed(() => {
      const user = root.$auth.user()
      return user && user.display_name
    })
    const contacts = computed(() => {
      const user = root.$auth.user()
      const collaborate = (user && user.collaborate) || {}
      return collaborate.contacts || []
    })

    function toggleInfo() {
      openInfo.value = !openInfo.value
    }

    function logout() {
      root.$auth.logout()
    }

    return {
      headerStyle,
      isLogin,
      openInfo,
      userName,
      contacts,
      toggleInfo,
      logout
    }
  }
})
