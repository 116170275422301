






























































































import {
  createComponent,
  reactive,
  ref,
  SetupContext
} from '@vue/composition-api'
import notifyService from '@/services/notify-service'
import FInput from '@/components/inputs/FInput.vue'
import { Dictionary } from 'vue-router/types/router'
import useCollaborateService from '@/composition/CollaborateService'

export default createComponent({
  name: 'FirmLogin',
  components: {
    FInput
  },

  setup(props, context) {
    const { form, isSending, codeBtn, isSendCode, sendCode, submit } = useForm(
      context
    )

    return {
      form,
      isSending,
      codeBtn,
      isSendCode,
      sendCode,
      submit
    }
  }
})

function useForm(context: SetupContext) {
  const { root } = context
  const {
    collaborate_info,
    requestOneTimePassword,
    firmLogin,
    setWithExpiry
  } = useCollaborateService()

  const codeBtn = ref('取得密碼')
  const codeCountdown = ref(10)
  const isSendCode = ref(false)
  const isSending = ref(false)
  const vat = getVat(root.$route.query)

  const form = reactive({
    vat: vat,
    email: '',
    code: '',
    type: 0
  })

  async function sendCode() {
    if (isSendCode.value) {
      return
    }
    if (!form.email) {
      notifyService.error('請輸入email')
      return
    }

    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (!form.email.match(re)) {
      notifyService.error('請確認email是否正確')
      return
    }

    isSendCode.value = true
    codeCountdown.value = 180
    codeBtn.value = codeCountdown.value + 's'
    const result = await requestOneTimePassword(form.email)
    if (result) {
      notifyService.success('密碼已寄到您的信箱。')
    } else {
      notifyService.error('無法連接到伺服器，請稍後再試！')
    }

    let interval = setInterval(() => {
      codeCountdown.value -= 1

      if (codeCountdown.value > 0) {
        codeBtn.value = codeCountdown.value + 's'
      } else {
        codeBtn.value = '取得密碼'
        clearInterval(interval)
      }
    }, 1000)
  }

  async function submit() {
    try {
      isSending.value = true
      const result = await firmLogin(form.vat!, form.email, form.code)
      const data = result.data

      const ttl = 6 * 60 * 60 * 1000 // 6小時
      setWithExpiry('FirmAuthToken', data.token, ttl)

      root.$router.push({ name: 'firm' }).catch(() => {})
    } catch (e) {
      notifyService.error(e.message)
    } finally {
      isSending.value = false
    }
  }

  return {
    collaborate_info,
    form,
    isSending,
    codeBtn,
    isSendCode,
    sendCode,
    submit
  }
}

function getVat(query: Dictionary<string | (string | null)[]>) {
  let vat: string | null = null
  if (query.v) {
    vat = query.v as string
    localStorage.setItem('vat', vat!)
  } else {
    const v = localStorage.getItem('vat')
    if (v) {
      vat = v
    }
  }

  return vat
}
