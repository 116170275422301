























































import { createComponent, ref } from '@vue/composition-api'
import { useStore } from '@/store'
export default createComponent({
  name: 'Protection',

  setup(props, ctx) {
    const store = useStore()

    const checkboxType = ref('')
    const isRead = ref(false)
    const isAgree = ref(false)
    isAgree.value = store.getters.isAgreeProtection

    function onAgree() {
      if (isRead.value) {
        store.dispatch('agreeProtection')
        isAgree.value = true
      } else {
        checkboxType.value = 'has-text-danger'
      }
    }

    return {
      checkboxType,
      isRead,
      isAgree,
      onAgree
    }
  }
})
