import Vue from 'vue'
import { configure } from 'vee-validate'
import '@/utils/validation'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values!._field_ = Vue.i18n.translate(`fields.${field}`)

    const message = Vue.i18n.translate(`validation.${values!._rule_}`, values)

    return message!
  }
})
