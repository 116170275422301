export const fields = {
  username: 'User Name',
  password: 'Password',

  name: 'Name',
  english_name: 'English Name',
  identity_number: 'ID',
  nationality: 'Nationality',
  gender: 'Gender',
  email: 'Email',
  emergency_contact: 'Emergency Contact',
  emergency_phone: 'Emergency Phone',
  relationship: 'Relationship',
  date_of_birth: 'Birth Date',
  passport_number: 'Passport Number',
  passport_expire: 'Passport Expire',
  mobile_phone: 'Mobile Phone',
  tel: 'Tel',
  home_address: 'Home Address',
  remark: 'Remark'
}

export const message = {
  fantasy_tours: 'Fantasy Tours',
  service_contact: 'Please contact your company service personnel',
  service_contact_tel: 'Please call {tel} to serve you'
}

export default {
  fields,
  message
}
