





































































































































































































import { createComponent, computed, onMounted, ref } from '@vue/composition-api'
import { useStore } from '@/store'
import { OrderStatus } from '@/types/constant'
import { TravelGroup, Order as OrderType } from '@/types/order'
import notifyService from '@/services/notify-service'
import useConstantService from '@/composition/ConstantService'
import useOrderService from '@/composition/OrderService'
import OrderDetails from '@/components/order/OrderDetails.vue'
import OrderDetailSignup from '@/components/order/OrderDetailSignup.vue'
import OrderDetailInfoCard from '@/components/order/OrderDetailInfoCard.vue'
import OrderCost from '@/components/order/OrderCost.vue'

export default createComponent({
  name: 'Order',
  components: {
    OrderDetails,
    OrderDetailSignup,
    OrderDetailInfoCard,
    OrderCost
  },
  props: {
    group: {
      type: Object as () => TravelGroup,
      required: true
    }
  },
  setup(props, ctx) {
    const { root, emit } = ctx
    const { group } = props
    const store = useStore()

    const showTutorialSignup = ref(true)
    const showTutorialPassengerSignup = ref(true)

    showTutorialSignup.value = store.getters.isShowTutorialSignup
    showTutorialPassengerSignup.value =
      store.getters.isShowTutorialPassengerSignup

    const showAdvance = ref(true)

    const signupTutorial = ref<HTMLDivElement>()
    const { navbar } = useConstantService()
    const {
      orderLoading,
      orderResult,
      order,
      fetchOrder,
      deleteOrder,
      updateOrder
    } = useOrderService(false)
    const canSignup = computed(() => {
      return (
        group!.is_registration_opening &&
        !group!.is_expired &&
        !order.value.is_modify_expired &&
        (order.value.status >= OrderStatus.Completed ||
          (!group!.is_full && !order.value.user_activity.is_signup))
      )
    })
    const canUpdate = computed(() => {
      return (
        group!.is_registration_opening &&
        !order.value.is_modify_expired &&
        order.value.status >= OrderStatus.Completed
      )
    })
    const showInfo = computed(() => {
      return (
        order.value.status >= OrderStatus.Completed &&
        (order.value.is_modify_expired || group!.is_registration_end)
      )
    })
    const showTotalFee = computed(() => {
      if (!group.activity || !group.activity.show_total_fee) {
        return false
      }

      return canSignup.value || canUpdate.value || showInfo.value
    })

    const showSignupHint = computed(() => {
      return (
        order.value.status >= OrderStatus.Completed && order.value.is_modified
      )
    })

    const signupButtonTitle = computed(() => {
      return order.value.status >= OrderStatus.Completed
        ? '更新報名資料'
        : '立即報名'
    })

    onMounted(async () => {
      await fetchOrder(root.$route.params.code)

      if (orderResult.value) {
        emit('set-status', order.value.status_message)
      }

      showAdvance.value = order.value.travel_group!.activity!.type === 0

      if (root.$route.query.m === 't') {
        root.$scrollTo('#signup-button', { offset: -navbar.height })
        await root.$router
          .replace({
            name: root.$route.name || 'home',
            params: root.$route.params
          })
          .catch(() => {})
      }
    })

    function onSignupOrder() {
      store.dispatch('hideTutorial')

      if (signupTutorial.value) {
        signupTutorial.value.style.setProperty('display', 'none')
      }

      const invalidDetails = order.value.temp_details.filter(detail => {
        return (
          !detail.name ||
          !detail.email ||
          !detail.emergency_contact ||
          !detail.emergency_phone ||
          !detail.relationship ||
          !detail.date_of_birth ||
          !detail.mobile_phone
        )
      })

      if (invalidDetails.length > 0) {
        return notifyService.alert(
          '請確實填寫旅客姓名、緊急連絡人、出生日期及本人電話',
          3000,
          () => {
            root.$scrollTo('#passenger-info-wrapper', {
              offset: -navbar.height
            })
          }
        )
      }

      const unfilledDetails = order.value.temp_details.filter(d => !d.is_filled)

      if (unfilledDetails.length > 0) {
        notifyService.confirm({
          title: '您確定要在資料未齊全狀態的下送出報名？',
          message:
            '送出後您可以在截止日期前隨時回來補齊所需資訊，若超過期限，行程規劃將由公司代為安排。',
          cancelText: '取消',
          confirmText: '確定',
          focusOn: 'cancel',
          onConfirm: () => {
            root.$router.push({
              name: 'travel-group-confirm',
              params: { code: order.value.group_code }
            })
          }
        })
      } else {
        root.$router.push({
          name: 'travel-group-confirm',
          params: { code: order.value.group_code }
        })
      }
    }

    const isEditingDetail = ref(false)
    function onEditDetail(isEditing: boolean) {
      isEditingDetail.value = isEditing
    }

    function onOrderModified(orderData: OrderType, showHint: boolean = true) {
      if (showHint) {
        showTutorial()
      }

      updateOrder(orderData)
      root.$scrollTo('#passenger-info-wrapper', { offset: -navbar.height })
    }

    function showTutorial() {
      store.dispatch('showTutorialSignup')
      store.dispatch('showTutorialPassengerSignup')
      showTutorialSignup.value = true
      showTutorialPassengerSignup.value = true
    }

    function hideTutorial() {
      store.dispatch('hideTutorial')
      showTutorialSignup.value = false
      showTutorialPassengerSignup.value = false
    }

    async function onDeleteOrder() {
      const loadingComponent = root.$buefy.loading.open({ container: null })

      const result = await deleteOrder(order.value.order_no!)

      loadingComponent.close()

      if (result.success) {
        notifyService.alert('退團完成', 3000, () => {
          root.$router.replace({ name: 'home' }).catch(() => {})
        })
      } else {
        notifyService.alert(result.message)
      }
    }

    return {
      orderResult,
      orderLoading,
      order,
      canSignup,
      canUpdate,
      showInfo,
      showTotalFee,
      showTutorialSignup,
      showTutorialPassengerSignup,
      signupTutorial,
      showSignupHint,
      signupButtonTitle,
      onSignupOrder,
      onOrderModified,
      onDeleteOrder,
      hideTutorial,
      onEditDetail,
      isEditingDetail
    }
  }
})
