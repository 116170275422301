import Vue from 'vue'
import VueAuth from '@d0whc3r/vue-auth-plugin'
import { LOGIN_API, PROFILE_API, TOKEN_REFRESH_API } from '@/utils/apis'

Vue.use(VueAuth, {
  authMeta: 'auth',
  rolesVar: 'roles',
  tokenDefaultName: 'auth_token',
  userDefaultName: 'auth_user',
  tokenStore: ['localStorage', 'vuex'],
  headerTokenReplace: '{auth_token}',
  tokenType: 'Bearer',
  vuexStoreSpace: 'auth',

  authRedirect: '/welcome',

  loginData: {
    url: LOGIN_API,
    method: 'POST',
    redirect: '/',
    headerToken: 'Authorization',
    fetchUser: true,
    customToken: response => response.data.data['auth_token']
  },
  logoutData: {
    redirect: '/login'
  },
  fetchItem: 'data',
  fetchData: {
    url: PROFILE_API,
    method: 'GET',
    interval: 1,
    enabled: true
  },
  refreshData: {
    url: TOKEN_REFRESH_API,
    method: 'POST',
    interval: 2,
    enabled: false
  }
})
