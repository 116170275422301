import bannerImage from '@/assets/images/trip-banner.jpg'

export default function useImageService() {
  function fantasyUrl(path: string | undefined) {
    if (!path) {
      return ''
    }

    if (path.startsWith('http')) {
      return path
    }

    return path.startsWith('/')
      ? `${process.env.VUE_APP_MAIN_SITE_ROOT_URL}${path}`
      : `${process.env.VUE_APP_MAIN_SITE_ROOT_URL}/${path}`
  }

  function fantasyImageUrl(image: string | undefined) {
    return `${process.env.VUE_APP_IMAGE_ROOT_URL}/Content/Images/Upload/${image}`
  }

  function productImageUrl(image: string | undefined) {
    return image ? fantasyImageUrl(`Product/${image}`) : ''
  }

  function productUserImageUrl(image: string | undefined) {
    return image ? fantasyImageUrl(`UserUpload/images/${image}`) : ''
  }

  function collaborateImageUrl(image: string | undefined) {
    return image ? fantasyImageUrl(`Member/${image}`) : ''
  }

  function collaborateActivityImageUrl(image: string | undefined) {
    return image ? fantasyImageUrl(`Activity/${image}`) : bannerImage
  }

  function passportImageUrl(image: string | undefined) {
    return image ? fantasyImageUrl(`Passport/${image}`) : null
  }

  return {
    fantasyUrl,
    fantasyImageUrl,
    productImageUrl,
    productUserImageUrl,
    collaborateImageUrl,
    collaborateActivityImageUrl,
    passportImageUrl
  }
}
