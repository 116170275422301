import dayjs from 'dayjs'

export default function useFormatService() {
  function formatDateWeek(date: string | undefined) {
    if (!date) {
      return null
    }

    return dayjs(date).format('YYYY/MM/DD(dd)')
  }

  function formatDate(date: Date | string | undefined | null) {
    if (!date) {
      return null
    }

    return dayjs(date).format('YYYY/MM/DD')
  }

  function formatWeek(date: string | undefined) {
    if (!date) {
      return null
    }

    return dayjs(date).format('(dd)')
  }

  /**
   * Currency format(num, n = 2, x)
   *
   * @param num number
   * @param n integer length of decimal
   * @param x integer length of sections
   */
  function formatCurrency(num: number, n: number = 0, x?: number) {
    if (!num) {
      return num
    }

    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
    return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
  }

  function genderById(id: string | null) {
    if (!id || id.length < 2) {
      return null
    }

    return id.substring(1, 2) === '1' ? '男' : '女'
  }

  function getAgeType(
    birthDate: Date,
    departureDate: Date,
    childAge: number,
    babyAge: number
  ): { age: number; ageType: number } {
    let age = -1
    let ageType = 0

    if (!birthDate || !departureDate) {
      return { age, ageType }
    }

    age = departureDate.getFullYear() - birthDate.getFullYear()
    const date = new Date(
      departureDate.getFullYear() - age,
      departureDate.getMonth(),
      departureDate.getDate()
    )

    // Go back to the year the person was born in case of a leap year
    if (birthDate > date) age--

    if (age >= childAge) {
      ageType = 0 // adult
    } else if (age >= babyAge) {
      ageType = 1 // child
    } else {
      ageType = 2 // Baby
    }

    return { age, ageType }
  }

  return {
    formatDateWeek,
    formatDate,
    formatWeek,
    formatCurrency,
    genderById,
    getAgeType
  }
}
