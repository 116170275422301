




















import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'FCheckboxes',
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    showHighlight: {
      type: Boolean,
      default: true
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    function getFieldStyle(errors: string[], valid: boolean) {
      if (!props.showHighlight) {
        return {}
      }

      return {
        'is-danger': !!errors[0],
        'is-success': valid
      }
    }

    function getErrorMessage(errors: string[]) {
      return props.showErrors ? errors : null
    }

    return {
      getFieldStyle,
      getErrorMessage
    }
  }
})
