import Vue from 'vue'
import { ToastProgrammatic as Toast } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'
import { BDialogConfig } from 'buefy/types/components'

const notifyService = {
  fetch_error(dataType: string, error: any) {
    const type = Vue.i18n.translate(`attributes.${dataType}`)
    const message = Vue.i18n.translate('message.fetch_failed', [type])

    this.error(message, error)
  },
  create_error(error: any) {
    const message = Vue.i18n.translate('attributes.add_failed')

    this.error(message, error)
  },
  update_error(error: any) {
    const message = Vue.i18n.translate('attributes.update_failed')

    this.error(message, error)
  },
  delete_error(error: any) {
    const message = Vue.i18n.translate('attributes.delete_failed')

    this.error(message, error)
  },
  error(msg: string | undefined, error: any = null) {
    let message = `${msg}`
    if (error) {
      if (typeof error === 'object') {
        message = `(${error.status}) ${msg}: ${error.message}`
      } else if (typeof error === 'string') {
        message = `${msg}: ${error}`
      }
    }

    Toast.open({
      message,
      duration: 5000,
      position: 'is-top',
      type: 'is-danger',
      queue: false
    })
  },
  success(msg: string | undefined, duration: number = 3000) {
    const message = `${msg}`

    Toast.open({
      message,
      duration: duration,
      position: 'is-top',
      type: 'is-success'
    })
  },
  show(prop: any) {
    Toast.open(prop)
  },

  alert(
    message: string,
    timeout: number = 0,
    callback?: () => any,
    type: string = 'is-secondary'
  ) {
    let handle: number | undefined
    const alert = Dialog.alert({
      message,
      type,
      onConfirm: () => {
        handle && clearTimeout(handle)
        callback && callback()
      }
    })

    if (timeout !== 0) {
      handle = setTimeout(() => {
        alert.close()
        callback && callback()
      }, timeout)
    }
  },

  confirm(params: BDialogConfig) {
    params = {
      cancelText: '取消',
      confirmText: '確定',
      type: 'is-secondary',
      focusOn: 'cancel',
      trapFocus: true,
      ...params
    }

    Dialog.confirm(params)
  }
}

export default notifyService
