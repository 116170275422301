

















import { createComponent, onMounted, ref } from '@vue/composition-api'
import useOrderService from '@/composition/OrderService'
import useOrderDetailService from '@/composition/OrderDetailService'
import notifyService from '@/services/notify-service'
import OrderDetailSignupForm from '@/components/order/OrderDetailSignupForm.vue'
import OrderDetailInfoCard from '@/components/order/OrderDetailInfoCard.vue'

export default createComponent({
  name: 'OrderDetailSignup',
  components: {
    OrderDetailSignupForm,
    OrderDetailInfoCard
  },
  props: {
    groupCode: {
      type: String,
      required: true
    },
    orderNo: {
      type: String,
      required: true
    },
    detailId: {
      type: Number,
      required: true
    }
  },
  setup(props, ctx) {
    const isSending = ref(false)
    const { root } = ctx
    const { groupCode, detailId } = props
    const { orderSending } = useOrderService(true)
    const {
      detailSending,
      detailLoading,
      detailResult,
      detail,
      fetchDetail,
      updateDetailAnswer
    } = useOrderDetailService()

    onMounted(async () => {
      await fetchDetail(detailId)

      if (!detailResult.value) {
        return notifyService.alert(
          '無法載入資料，請聯絡服務人員！',
          3000,
          () => {
            root.$router.push({ name: 'login' }).catch(() => {})
          }
        )
      }
    })

    async function onSignup(data: object) {
      const result = await updateDetailAnswer(detailId, data)

      if (!result.success) {
        notifyService.alert(result.message, 3000)
        return
      }

      await root.$router.push({
        name: 'travel-group-confirm',
        params: { code: groupCode }
      })
    }

    function onSending(sending: boolean) {
      isSending.value = sending
    }

    return {
      isSending,
      detailSending,
      detailLoading,
      detailResult,
      orderSending,
      detail,
      onSignup,
      onSending
    }
  }
})
