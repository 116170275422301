






















































































































































































































































































import { computed, createComponent, ref, watch } from '@vue/composition-api'
import { validate } from 'vee-validate'
import { OrderDetail, TravelerType } from '@/types/order'
import { AgeTypes, BedTypes, BedNames } from '@/types/constant'
import useConstantService from '@/composition/ConstantService'
import useFormatService from '@/composition/FormatService'
import notifyService from '@/services/notify-service'
import FInput from '@/components/inputs/FInput.vue'
import FCheckboxes from '@/components/inputs/FCheckboxes.vue'
import FRadios from '@/components/inputs/FRadios.vue'
import FSelect from '@/components/inputs/FSelect.vue'
import FDatepicker from '@/components/inputs/FDatepicker.vue'
import useOrderDetailService from '@/composition/OrderDetailService'

export default createComponent({
  name: 'OrderDetailForm',
  components: {
    FInput,
    FRadios,
    FSelect,
    FDatepicker,
    FCheckboxes
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    showBedType: {
      type: Boolean,
      default: false
    },
    showEnglishName: {
      type: Boolean,
      default: true
    },
    showPassport: {
      type: Boolean,
      default: true
    },
    showCompanyTel: {
      type: Boolean,
      default: false
    },
    showHomeAddress: {
      type: Boolean,
      default: false
    },
    showRelationship: {
      type: Boolean,
      default: false
    },
    orderNo: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    data: {
      type: Object as () => OrderDetail,
      required: true
    },
    freeCount: {
      type: Number,
      default: 0
    },
    depDate: {
      type: Date,
      required: true
    },
    childAge: {
      type: Number,
      default: 12
    },
    babyAge: {
      type: Number,
      default: 3
    },
    travelerTypes: {
      type: Array as () => Array<TravelerType>,
      required: true
    }
  },
  setup(props, ctx) {
    const { root, emit } = ctx
    const { navbar, genderOptions, nationalityOptions } = useConstantService()
    const { formatDate, genderById, getAgeType } = useFormatService()
    const { addDetail, updateDetail } = useOrderDetailService()

    const detail = ref<OrderDetail>({})

    const today = new Date()
    const minBirthDate = ref(new Date(today.getFullYear() - 120, 0, 1))
    const maxBirthDate = ref(new Date(today.getFullYear(), 11, 31))

    const ageTypeNames = computed(() => [
      '成人',
      `孩童${props.childAge}歲以下`,
      `嬰兒${props.babyAge}歲以下`
    ])

    const travelerTypeOptions = ref(
      props.travelerTypes
        .sort((a: any, b: any): number => a.sort - b.sort)
        .map(t => {
          return {
            ...t,
            disabled: false
          }
        })
    )

    detail.value = extractInfo(props.data)

    const bedOptions = computed(() => {
      return function(detail: OrderDetail) {
        return [
          {
            disabled: detail.age_type !== AgeTypes.Adult,
            value: BedTypes.Adult,
            name: BedNames[BedTypes.Adult]
          },
          {
            disabled: detail.age_type !== AgeTypes.Child,
            value: BedTypes.ChildBed,
            name: BedNames[BedTypes.ChildBed]
          },
          {
            disabled: detail.age_type !== AgeTypes.Child,
            value: BedTypes.ChildNoBed,
            name: BedNames[BedTypes.ChildNoBed]
          },
          {
            disabled: detail.age_type !== AgeTypes.Baby,
            value: BedTypes.Baby,
            name: BedNames[BedTypes.Baby]
          },
          {
            disabled: false,
            value: BedTypes.AddBed,
            name: BedNames[BedTypes.AddBed]
          }
        ]
      }
    })

    const formId = computed(
      () => `order-detail-form-${props.index === -1 ? 'new' : props.index + 1}`
    )
    const isSelf = computed(() => props.index === 0)
    const showErrors = ref<boolean>(false)

    watch(
      () => props.data,
      () => {
        detail.value = extractInfo(props.data)
      }
    )

    watch(
      () => detail.value.identity_number,
      () => {
        if (detail.value.nationality === 1) {
          validate(detail.value.identity_number, 'required|taiwan_id', {
            name: 'identity_number'
          }).then(result => {
            if (result.valid) {
              detail.value.gender = genderById(detail.value.identity_number)
            }
          })
        }
      }
    )

    watch(
      () => detail.value.date_of_birth,
      () => {
        const departureDate: Date = (props.depDate as unknown) as Date
        const { age, ageType } = getAgeType(
          detail.value.date_of_birth as Date,
          departureDate,
          props.childAge,
          props.babyAge
        )
        let bedType = detail.value.bed_type

        if (detail.value.age_type !== ageType) {
          if (ageType == AgeTypes.Baby) {
            bedType = BedTypes.Baby
          } else if (ageType == AgeTypes.Child) {
            bedType = BedTypes.ChildBed
          } else {
            bedType = BedTypes.Adult
          }
        }

        detail.value.age = age
        detail.value.age_type = ageType
        detail.value.bed_type = bedType

        travelerTypeOptions.value = props.travelerTypes
          .sort((a: any, b: any): number => a.sort - b.sort)
          .map(t => {
            return {
              ...t,
              disabled: !(
                age >= t.traveler_type.age_min && age < t.traveler_type.age_max
              )
            }
          })

        const types = travelerTypeOptions.value.filter(t => !t.disabled)

        if (detail.value.traveler_type !== null) {
          if (
            types.findIndex(
              t => t.traveler_type.name === detail.value.traveler_type
            ) === -1
          ) {
            if (travelerTypeOptions.value.length === 1) {
              detail.value.traveler_type =
                travelerTypeOptions.value[0].traveler_type.name
            } else {
              detail.value.traveler_type =
                types.length > 0 ? types[0].traveler_type.name : null
            }
          }
        }
      }
    )

    function extractInfo(data: OrderDetail): OrderDetail {
      return {
        id: data.id,
        is_self: data.is_self,
        order_no: data.order_no,
        formal_detail_id: data.formal_detail_id,
        type: data.type,
        type_message: data.type_message,
        status: data.status,
        status_message: data.status_message,
        mark_remove: data.mark_remove,
        is_modified: data.is_modified,
        is_free: data.is_free,
        name: data.name,
        nationality: data.nationality,
        identity_number: data.identity_number,
        gender: data.gender,
        english_name: data.english_name,
        email: data.email,
        emergency_contact: data.emergency_contact,
        emergency_phone: data.emergency_phone,
        relationship: data.relationship,
        date_of_birth: data.date_of_birth && new Date(data.date_of_birth),
        age: data.age,
        age_type: data.age_type,
        bed_type: data.bed_type,
        traveler_type: data.traveler_type,
        passport_number: data.passport_number,
        passport_expire: data.passport_expire && new Date(data.passport_expire),
        passport_image: data.passport_image,
        mobile_phone: data.mobile_phone,
        tel: data.tel,
        home_address: data.home_address,
        remark: data.remark,
        common_answers: [],
        spot_answers: []
      }
    }

    function onCancel() {
      detail.value = extractInfo(props.data)
      emit('cancel', detail.value.id)
    }

    function onSubmit() {
      ctx.refs.observer.validate().then(async success => {
        if (!success) {
          notifyService.error('欄位資訊錯誤，請再檢查一次')
          root.$scrollTo(`#${formId.value}`, { offset: -navbar.height })
          return
        }

        const date_of_birth = formatDate(detail.value.date_of_birth)
        const passport_expire = formatDate(detail.value.passport_expire)

        const form: OrderDetail = {
          ...detail.value,
          date_of_birth: date_of_birth!,
          passport_expire: passport_expire!
        }

        if (detail.value.id === -1) {
          const loadingComponent = root.$buefy.loading.open({ container: null })
          const result = await addDetail(props.orderNo!, form)
          loadingComponent.close()

          if (result.success) {
            const order = result.data

            const newDetail = order.temp_details.find((d: OrderDetail) => {
              return (
                d.name == detail.value.name &&
                d.relationship == detail.value.relationship
              )
            })

            notifyService.confirm({
              title: '新增旅客成功',
              message: '是否繼續填寫參團報名表？',
              cancelText: '稍後',
              confirmText: '繼續填寫',
              onConfirm: () => {
                root.$router.push({
                  name: 'order-detail-signup',
                  params: { id: newDetail.id.toString() }
                })
              },
              onCancel: () => emit('saved', -1, result.data)
            })
          } else {
            notifyService.alert(result.message, 3000)
            root.$scrollTo(`#${formId.value}`, { offset: -navbar.height })
          }
        } else {
          const loadingComponent = root.$buefy.loading.open({ container: null })
          const result = await updateDetail(detail.value.id, form)
          loadingComponent.close()

          if (result.success) {
            emit('saved', detail.value.id, result.data)
          } else {
            notifyService.alert(result.message, 3000)
            root.$scrollTo(`#${formId.value}`, { offset: -navbar.height })
          }
        }
      })
    }

    return {
      formId,
      isSelf,
      detail,
      genderOptions,
      nationalityOptions,
      minBirthDate,
      maxBirthDate,
      bedOptions,
      travelerTypeOptions,
      ageTypeNames,
      showErrors,
      onCancel,
      onSubmit,
      formatDate
    }
  }
})
