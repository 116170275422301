





















































































































import { createComponent, computed, ref } from '@vue/composition-api'
import { OrderDetail as OrderDetailType } from '@/types/order'
import { BedNames, ageTypeNames, BedTypes } from '@/types/constant'
import useFormatService from '@/composition/FormatService'
import useImageService from '@/composition/ImageService'
import OrderDetailField from '@/components/order/fields/OrderDetailField.vue'
import OrderDetailLinkField from '@/components/order/fields/OrderDetailLinkField.vue'

export default createComponent({
  name: 'OrderDetailInfoCard',
  components: {
    OrderDetailField,
    OrderDetailLinkField
  },
  props: {
    detail: {
      type: Object as () => OrderDetailType,
      required: true
    },
    showAdvance: {
      type: Boolean,
      default: true
    },
    showBedType: {
      type: Boolean,
      default: false
    },
    showEnglishName: {
      type: Boolean,
      default: true
    },
    showPassport: {
      type: Boolean,
      default: true
    },
    showPassportImage: {
      type: Boolean,
      default: false
    },
    showCompanyTel: {
      type: Boolean,
      default: false
    },
    showHomeAddress: {
      type: Boolean,
      default: false
    },
    showRelationship: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { detail, showAdvance } = props
    const { formatDate } = useFormatService()
    const { passportImageUrl } = useImageService()
    const bedTypeName = computed(() => {
      const BedName = BedNames[detail.bed_type]

      if (detail.bed_type === BedTypes.AddBed) {
        const ageTypeName = ageTypeNames[detail.age_type]
        return `${ageTypeName}(${BedName})`
      }

      return BedName
    })
    const commonAnswers = computed(() => {
      return detail.common_answers.sort((a, b) => a.sequence - b.sequence)
    })
    const spotAnswers = computed(() => {
      return detail.spot_answers.sort((a, b) => a.sequence - b.sequence)
    })

    return {
      bedTypeName,
      commonAnswers,
      spotAnswers,
      formatDate,
      passportImageUrl
    }
  }
})
