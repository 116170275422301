






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  createComponent,
  onBeforeMount,
  reactive,
  ref,
  computed,
  SetupContext
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import useCollaborateService from '@/composition/CollaborateService'
import notifyService from '@/services/notify-service'
import FInput from '@/components/inputs/FInput.vue'
import FSelect from '@/components/inputs/FSelect.vue'
import FDatepicker from '@/components/inputs/FDatepicker.vue'
import useFormatService from '@/composition/FormatService'

export default createComponent({
  name: 'Firm',
  components: {
    FInput,
    FSelect,
    FDatepicker
  },
  setup(props, context) {
    const { root } = context

    const {
      collaborate_info,
      fetchFirmInfo,
      getWithExpiry
    } = useCollaborateService()

    onBeforeMount(async () => {
      const token = getWithExpiry('FirmAuthToken')

      if (!token) {
        notifyService.alert('登入憑證已過期', 5000, () => {
          root.$router.push({ name: 'firm-login' }).catch(() => {})
        })
      } else {
        const result = await fetchFirmInfo(token)

        if (!result) {
          notifyService.alert('登入憑證已過期', 5000, () => {
            root.$router.push({ name: 'firm-login' }).catch(() => {})
          })
        }
      }
    })

    const { formatDate } = useFormatService()

    const {
      observer,
      contactPanelOpen,
      ticketsPanelOpen,
      travelPanelOpen,
      hotelPanelOpen,
      otherPanelOpen,
      personsPanelOpen,
      features,
      featureName,
      agentOptions,
      hasDemand,
      hasTravel,
      hasPersons,
      form,
      isSending,
      isUploading,
      canAddFlight,
      addFlight,
      removeFlight,
      canAddPerson,
      addPerson,
      removePerson,
      onSelectedFile,
      submit
    } = useForm(context)

    function onSelectFeature(type: string, value: boolean) {
      if (!value) {
        return
      }

      let id = ''
      switch (type) {
        case features.TICKETS:
          id = 'tickets-panel'
          break
        case features.FREE:
        case features.GROUP:
        case features.INTERNAL:
          id = 'travel-panel'
          break
        case features.HOTEL:
          id = 'hotel-panel'
          break
        case features.OTHER:
          id = 'other-panel'
          break
        default:
          break
      }

      if (id) {
        setTimeout(() => {
          root.$scrollTo(`#${id}`, { offset: -80 })
        }, 100)
      }
    }

    function checkIcon(item: any, type: string) {
      return item.includes(type)
        ? 'checkbox-marked-outline'
        : 'checkbox-blank-outline'
    }

    return {
      observer,
      contactPanelOpen,
      ticketsPanelOpen,
      travelPanelOpen,
      hotelPanelOpen,
      otherPanelOpen,
      personsPanelOpen,
      features,
      featureName,
      onSelectFeature,
      agentOptions,
      collaborate_info,
      form,
      isSending,
      isUploading,
      hasDemand,
      hasTravel,
      hasPersons,
      canAddFlight,
      addFlight,
      removeFlight,
      canAddPerson,
      addPerson,
      removePerson,
      onSelectedFile,
      submit,
      checkIcon,
      formatDate
    }
  }
})

function useForm(context: SetupContext) {
  const { root } = context
  const { createFirmOrder, uploadFile, getWithExpiry } = useCollaborateService()

  const features = {
    TICKETS: 'tickets',
    INTERNAL: 'internal',
    FREE: 'free',
    GROUP: 'group',
    HOTEL: 'hotel',
    OTHER: 'other'
  }
  const featureName = {
    tickets: '代訂機栗',
    internal: '國內旅遊',
    free: '機＋酒自由行',
    group: '團體旅遊',
    hotel: '代訂飯店',
    other: '其他'
  }
  const agentOptions = ['護照', '簽證', '台胞證', '旅行平安險']
  const observer = ref(null)
  const contactPanelOpen = ref(true)
  const ticketsPanelOpen = ref(true)
  const travelPanelOpen = ref(true)
  const hotelPanelOpen = ref(true)
  const otherPanelOpen = ref(true)
  const personsPanelOpen = ref(true)
  const isSending = ref(false)
  const isUploading = ref(false)
  const form = reactive({
    demand: [] as string[],
    username: null as string | null,
    number: null as string | null,
    department: null as string | null,
    tel: null as string | null,
    mobile: null as string | null,
    email: null as string | null,
    lineId: null as string | null,
    notes: null as string | null,
    tickets: {
      flights: [
        {
          id: uuidv4(),
          departureDate: null as Date | null,
          departure: null as string | null,
          destination: null as string | null,
          airline: null as string | null,
          flyClass: null as string | null,
          notes: null as string | null
        }
      ]
    },
    travel: {
      departureDate: null as Date | null,
      returnDate: null as Date | null,
      departure: null as string | null,
      destination: null as string | null,
      airline: null as string | null,
      flyClass: null as string | null,
      notes: null as string | null
    },
    hotel: {
      destination: null as string | null,
      hotelName: null as string | null,
      person: null as number | null,
      checkinDate: null as Date | null,
      checkoutDate: null as Date | null,
      bed: null as string | null,
      passport: null as string | null,
      passportFile: null as File | null,
      notes: null as string | null
    },
    other: {
      agent: [] as string[],
      notes: null as string | null
    },
    persons: [
      {
        id: uuidv4(),
        username: null as string | null,
        birthDate: null as Date | null,
        firstName: null as string | null,
        lastName: null as string | null,
        identity: null as string | null,
        gender: null as string | null,
        passport: null as string | null,
        passportFile: null as File | null,
        notes: null as string | null
      }
    ]
  })

  function hasDemand(type: string) {
    return form.demand.includes(type)
  }

  const hasTravel = computed(() => {
    return (
      hasDemand(features.FREE) ||
      hasDemand(features.GROUP) ||
      hasDemand(features.INTERNAL)
    )
  })

  const hasPersons = computed(() => {
    return (
      hasDemand(features.TICKETS) ||
      hasDemand(features.FREE) ||
      hasDemand(features.GROUP) ||
      hasDemand(features.INTERNAL)
    )
  })

  function canAddFlight() {
    return form.tickets.flights.length < 10
  }

  function addFlight() {
    if (!canAddFlight()) {
      return
    }

    form.tickets.flights.push({
      id: uuidv4(),
      departureDate: null,
      departure: null,
      destination: null,
      airline: null,
      flyClass: null,
      notes: null
    })

    setTimeout(() => {
      const id = `tickets-flight-${form.tickets.flights.length - 1}`
      root.$scrollTo(`#${id}`, { offset: -80 })
    }, 100)
  }

  function removeFlight(flight: any) {
    form.tickets.flights = form.tickets.flights.filter(f => f.id !== flight.id)
  }

  function canAddPerson() {
    return form.persons.length < 10
  }

  function addPerson() {
    if (!canAddPerson()) {
      return
    }

    form.persons.push({
      id: uuidv4(),
      username: null,
      birthDate: null,
      firstName: null,
      lastName: null,
      identity: null,
      gender: null,
      passport: null,
      passportFile: null,
      notes: null
    })

    setTimeout(() => {
      const id = `person-${form.persons.length - 1}`
      root.$scrollTo(`#${id}`, { offset: -80 })
    }, 100)
  }

  function removePerson(flight: any) {
    form.persons = form.persons.filter(f => f.id !== flight.id)
  }

  async function onSelectedFile(item: any) {
    if (item.passportFile) {
      isUploading.value = true
      const result = await uploadFile(item.passportFile!)
      isUploading.value = false

      if (result.success) {
        item.passport = result.data.url
      } else {
        notifyService.error(`護照檔案上傳失敗，請稍後再試！`)
        return
      }
    }
  }

  async function submit() {
    if (!observer.value) {
      return
    }

    const token = getWithExpiry('FirmAuthToken')

    if (!token) {
      notifyService.alert('登入憑證已過期', 5000, () => {
        root.$router.push({ name: 'firm-login' }).catch(() => {})
      })
      return
    }

    const observerValue = observer.value! as any

    observerValue.validate().then(async (success: boolean) => {
      if (!success) {
        const el = document.querySelector('.is-errors')

        if (el) {
          const panel = el.closest('.collapse-content')

          if (panel) {
            if (panel.id === 'contactPanel') {
              contactPanelOpen.value = true
            } else if (panel.id === 'ticketsPanel') {
              ticketsPanelOpen.value = true
            } else if (panel.id === 'travelPanel') {
              travelPanelOpen.value = true
            } else if (panel.id === 'hotelPanel') {
              hotelPanelOpen.value = true
            } else if (panel.id === 'otherPanel') {
              otherPanelOpen.value = true
            } else if (panel.id === 'personsPanel') {
              personsPanelOpen.value = true
            }
          }

          setTimeout(() => {
            root.$scrollTo(el, { offset: -80 })
          }, 100)
        }

        return
      }

      if (hasDemand(features.HOTEL)) {
        if (!form.hotel.passportFile) {
          hotelPanelOpen.value = true

          const el = document.querySelector('#hotel-panel')

          if (el) {
            setTimeout(() => {
              root.$scrollTo(el, { offset: -80 })
            }, 100)
          }

          notifyService.error('請上傳護照檔案')
          return
        }
      }

      if (
        hasDemand(features.TICKETS) ||
        hasDemand(features.FREE) ||
        hasDemand(features.GROUP) ||
        hasDemand(features.INTERNAL)
      ) {
        let personIdx = null

        for (let idx = 0; idx < form.persons.length; idx++) {
          if (!form.persons[idx].passportFile) {
            personIdx = idx
            break
          }
        }

        if (personIdx !== null) {
          const el = document.querySelector(`#person-${personIdx}`)

          if (el) {
            setTimeout(() => {
              root.$scrollTo(el, { offset: -80 })
            }, 100)
          }

          notifyService.error('請上傳護照檔案')
          return
        }
      }

      try {
        let tickets = {} as any
        let travel = {} as any
        let hotel = {} as any
        let other = {} as any
        let persons = [] as any[]

        if (hasDemand(features.TICKETS)) {
          tickets = {
            flights: form.tickets.flights.map(t => ({ ...t }))
          }
        }

        if (
          hasDemand(features.FREE) ||
          hasDemand(features.GROUP) ||
          hasDemand(features.INTERNAL)
        ) {
          travel = { ...form.travel }
        }

        if (hasDemand(features.HOTEL)) {
          hotel = {
            ...form.hotel,
            person: form.hotel.person ? +form.hotel.person : null
          }
          delete hotel.passportFile
        }

        if (hasDemand(features.OTHER)) {
          other = { ...form.other }
        }

        if (
          hasDemand(features.TICKETS) ||
          hasDemand(features.FREE) ||
          hasDemand(features.GROUP) ||
          hasDemand(features.INTERNAL)
        ) {
          persons = form.persons.map(p => {
            const person = { ...p }
            delete person.passportFile
            return person
          })
        }

        const data = {
          token,
          ...form,
          demand: JSON.stringify(form.demand),
          tickets: JSON.stringify(tickets),
          travel: JSON.stringify(travel),
          hotel: JSON.stringify(hotel),
          other: JSON.stringify(other),
          persons: JSON.stringify(persons)
        }

        isSending.value = true
        const result = await createFirmOrder(data)

        root.$router.push({ name: 'firm-complete' }).catch(() => {})
      } catch (e) {
        notifyService.error('資料錯誤，請重新輸入')
      } finally {
        isSending.value = false
      }
    })
  }

  return {
    observer,
    contactPanelOpen,
    ticketsPanelOpen,
    travelPanelOpen,
    hotelPanelOpen,
    otherPanelOpen,
    personsPanelOpen,
    features,
    featureName,
    agentOptions,
    hasDemand,
    hasTravel,
    hasPersons,
    form,
    isSending,
    isUploading,
    canAddFlight,
    addFlight,
    removeFlight,
    canAddPerson,
    addPerson,
    removePerson,
    onSelectedFile,
    submit
  }
}
