/* eslint-disable no-console */
import Vue from 'vue'
import VueAxios from 'vue-axios'
import Auth from '@d0whc3r/vue-auth-plugin/src/lib/auth'
import axios, { AxiosResponse } from 'axios'

const instance = axios.create({
  baseURL: `${location.protocol}//${location.host}`,
  timeout: 5 * 60 * 1000,
  headers: {
    Pragma: 'no-cache',
    Accept: 'application/json',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
    'Content-type': 'application/json',
    'Accept-Language': 'zh-Hant'
  }
})

instance.interceptors.request.use(
  config => {
    // @ts-ignore
    const auth = Vue.$auth as Auth
    const token = auth.token()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    console.error('axios response', error.config, error.toJSON())

    const response = error.response
    let status = 500
    let message = null
    let errors = null

    if (response) {
      console.error('status', error)

      status = response.status
      message = getErrorMessage(response)

      if (status === 401) {
        return Promise.reject({ ...error, status, message })
      }

      errors = response.data && response.data.errors
    } else {
      message = error.message

      if (message === 'Network Error') {
        message = '服務無法連線，請檢察網路是否正常！'
      }

      // Something happened in setting up the request that triggered an Error
      console.error('error message', error.message)
    }

    return Promise.reject({ status, message, errors })
  }
)

function getErrorMessage(response: AxiosResponse) {
  if (response.status === 401) {
    return '登入已過期，請重新登入！'
  } else if (response.status === 403) {
    return '權限不足！'
  } else if (response.status === 404) {
    return '無此資源連結！'
  }

  const data = response.data

  if (data) {
    if (data.errors && data.errors.length > 0) {
      return data.errors[0]
    } else if (data.message) {
      return data.message
    }
  }

  return '未知錯誤！！'
}

Vue.use(VueAxios, instance)

export default instance
