






































import { computed, createComponent, ref, watch } from '@vue/composition-api'
import { Order, OrderDetail } from '@/types/order'
import { useStore } from '@/store'
import useConstantService from '@/composition/ConstantService'
import OrderDetailInfo from '@/components/order/OrderDetailInfo.vue'
import OrderDetailForm from '@/components/order/OrderDetailForm.vue'
import notifyService from '@/services/notify-service'

export default createComponent({
  name: 'OrderDetail',
  components: {
    OrderDetailInfo,
    OrderDetailForm
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    isLastPassenger: {
      type: Boolean,
      required: false
    },
    canDeleteDetail: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    showBedType: {
      type: Boolean,
      default: false
    },
    showEnglishName: {
      type: Boolean,
      default: true
    },
    showPassport: {
      type: Boolean,
      default: true
    },
    showCompanyTel: {
      type: Boolean,
      default: false
    },
    showHomeAddress: {
      type: Boolean,
      default: false
    },
    showRelationship: {
      type: Boolean,
      default: false
    },
    orderNo: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    data: {
      type: Object as () => OrderDetail,
      required: true
    },
    freeCount: {
      type: Number,
      default: 0
    },
    depDate: {
      type: Date,
      required: true
    },
    childAge: {
      type: Number,
      default: 12
    },
    babyAge: {
      type: Number,
      default: 3
    },
    travelerTypes: {
      type: Array,
      required: true
    },
    showTutorialPassengerSignup: {
      type: Boolean,
      required: false
    }
  },
  setup(props, ctx) {
    const { root, emit } = ctx
    const { navbar } = useConstantService()
    const store = useStore()

    const detail = ref<OrderDetail>({ ...props.data })
    const isSelf = computed(() => props.index === 0)
    const isEditing = ref(false)

    watch(
      () => props.data,
      () => {
        detail.value = { ...props.data }
      }
    )

    function onEdit() {
      isEditing.value = true
      root.$scrollTo(`#order-detail-${props.index + 1}`, {
        offset: -navbar.height
      })
      emit('edit', detail.value.id)
    }

    function onUpdated(id: number, order: Order) {
      isEditing.value = false
      root.$scrollTo('#passenger-info-wrapper', { offset: -navbar.height })

      emit('updated', id, order)
    }

    function onCancel() {
      isEditing.value = false
      root.$scrollTo('#passenger-info-wrapper', { offset: -navbar.height })

      emit('close', detail.value.id)
    }

    function onSignup() {
      if (!detail.value.date_of_birth) {
        return notifyService.alert('請先填寫基本資料')
      }

      store.dispatch('hideTutorial')

      root.$router.push({
        name: 'order-detail-signup',
        params: { id: detail.value.id.toString() }
      })
    }

    function onDelete() {
      if (isSelf.value) {
        if (!props.canCancel) {
          return
        }

        notifyService.confirm({
          message: '您確定要退團嗎？',
          onConfirm: () => emit('delete-order')
        })
      } else {
        notifyService.confirm({
          title: '您確定要刪除嗎？',
          message: '一旦刪除後，就無法再復原資料了。',
          cancelText: '取消',
          confirmText: '刪除',
          onConfirm: () => emit('delete-detail', detail.value)
        })
      }
    }

    return {
      isEditing,
      isSelf,
      detail,
      onEdit,
      onCancel,
      onUpdated,
      onSignup,
      onDelete
    }
  }
})
