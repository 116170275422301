

































































































import {
  createComponent,
  ref,
  reactive,
  toRefs,
  SetupContext,
  onMounted
} from '@vue/composition-api'
import useCollaborateService from '@/composition/CollaborateService'
import notifyService from '@/services/notify-service'
import FInput from '@/components/inputs/FInput.vue'
import { Dictionary } from 'vue-router/types/router'

export default createComponent({
  name: 'Login',
  components: {
    FInput
  },
  setup(props, context) {
    const { root } = context

    const collaborate_id = getCollaborateId(root.$route.query)
    root.$router
      .replace({
        name: root.$route.name || 'home',
        query: { c: collaborate_id.toString() }
      })
      .catch(() => {})

    const { collaborate_info, fetchCollaborateInfo } = useCollaborateService()
    const { form, isSending, submit } = useForm(context, collaborate_id)

    if (root.$auth.check()) {
      root.$router.push({ name: 'home' }).catch(() => {})
    } else {
      fetchCollaborateInfo(collaborate_id)
    }

    return {
      ...toRefs(collaborate_info),
      ...toRefs(form),
      isSending,
      submit
    }
  }
})

function getCollaborateId(query: Dictionary<string | (string | null)[]>) {
  let collaborate_id: number | null = null
  if (query.c) {
    const id = parseInt(query.c as string)

    if (!isNaN(id)) {
      collaborate_id = id
      localStorage.setItem('cid', collaborate_id.toString())
    }
  } else {
    const id = localStorage.getItem('cid')
    if (id) {
      collaborate_id = parseInt(id)
    }
  }
  if (collaborate_id == null) {
    collaborate_id = 2
  }

  return collaborate_id
}

function useForm(context: SetupContext, collaborate_id: number) {
  const isSending = ref(false)
  const form = reactive({
    username: '',
    password: '',
    remember_me: false
  })

  async function submit() {
    try {
      isSending.value = true
      await context.root.$auth.login({ collaborate_id, ...form })
    } catch (e) {
      form.password = ''

      notifyService.error('登入資訊錯誤，請重新輸入')
    } finally {
      isSending.value = false
    }
  }

  return {
    form,
    isSending,
    submit
  }
}
