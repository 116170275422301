












import { createComponent, computed } from '@vue/composition-api'
import FantasyNavbar from '@/components/layouts/Navbar.vue'
import FantasyFooter from '@/components/layouts/Footer.vue'

export default createComponent({
  name: 'App',
  components: {
    FantasyNavbar,
    FantasyFooter
  },
  setup(props, { root }) {
    const isLoginPage = computed(() => {
      return root.$route.name === 'login' || root.$route.name === 'welcome'
    })

    return {
      isLoginPage
    }
  }
})
