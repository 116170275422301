






























import { createComponent, onMounted, ref } from '@vue/composition-api'
import { useStore } from '@/store'
import useOrderDetailService from '@/composition/OrderDetailService'
import useFormatService from '@/composition/FormatService'
import notifyService from '@/services/notify-service'
import OrderDetailSignupForm from '@/components/order/OrderDetailSignupForm.vue'
import OrderDetailInfoCard from '@/components/order/OrderDetailInfoCard.vue'

export default createComponent({
  name: 'OrderDetailSignupView',
  components: {
    OrderDetailSignupForm,
    OrderDetailInfoCard
  },
  setup(props, ctx) {
    const isSending = ref(false)
    const { root } = ctx
    const { formatDateWeek } = useFormatService()
    const {
      detailSending,
      detailLoading,
      detailResult,
      detail,
      fetchDetail,
      updateDetailAnswer
    } = useOrderDetailService()
    const store = useStore()

    onMounted(async () => {
      await fetchDetail(parseInt(root.$route.params.id))

      if (!detailResult.value) {
        return notifyService.alert(
          '無法載入資料，請聯絡服務人員！',
          3000,
          () => {
            root.$router.push({ name: 'login' }).catch(() => {})
          }
        )
      }
    })

    async function onSignup(data: object) {
      const result = await updateDetailAnswer(detail.value.id, data)

      if (result.success) {
        await store.dispatch('showTutorialSignup')

        notifyService.alert('儲存成功', 3000, () => {
          root.$router.push({
            name: 'travel-group',
            params: { code: detail.value.group_code! },
            query: { m: 't' }
          })
        })
      } else {
        notifyService.alert(result.message, 3000)
      }
    }

    function onSending(sending: boolean) {
      isSending.value = sending
    }

    return {
      isSending,
      detailSending,
      detailLoading,
      detailResult,
      detail,
      onSignup,
      onSending,
      formatDateWeek
    }
  }
})
