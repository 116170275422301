import { reactive, ref } from '@vue/composition-api'

export default function useConstantService() {
  const navbar = reactive({
    height: 66
  })

  const genderOptions = ref([
    { name: '男', value: '男' },
    { name: '女', value: '女' }
  ])

  const nationalityOptions = ref([
    { name: '本國人', value: 1 },
    { name: '外國人', value: 2 }
  ])

  return {
    navbar,
    genderOptions,
    nationalityOptions
  }
}
