import { ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { CommonQuestion, OrderDetail, TravelSpotQuestion } from '@/types/order'
import {
  OrderDetailStatus,
  QuestionOptionTypes,
  QuestionTypes
} from '@/types/constant'
import dayjs from 'dayjs'

export default function useOrderDetailService() {
  const detailLoading = ref(true)
  const detailSending = ref(false)
  const detailResult = ref(false)
  const detail = ref<OrderDetail>({
    id: null,
    order: {},
    group_name: '-'
  })

  async function fetchDetail(id: number) {
    try {
      detailLoading.value = true
      const result = await axios.get(`api/order-details/${id}`)
      updateOrderDetail(result.data.data)
      detailResult.value = true
      return result.data
    } catch (e) {
      detailResult.value = false
      // eslint-disable-next-line no-console
      console.error('fetch OrderDetail failed: ', e.message)
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailLoading.value = false
    }
  }

  function updateOrderDetail(data: OrderDetail) {
    detail.value = normalizeDetail(data, 0)
  }

  function normalizeDetail(detail: OrderDetail, index: number) {
    const is_self = index === 0
    const type = is_self ? '本人' : detail.is_free ? '免費' : '自費'
    const status =
      detail.status == OrderDetailStatus.New
        ? '(未報名)'
        : detail.is_modified
        ? '(修改未送出)'
        : '(完成報名)'
    const common_answers = detail.common_answers
      .sort((q1, q2) => q1.sequence - q2.sequence)
      .map(question => {
        const is_single_select = question.type === QuestionTypes.Select
        const is_multi_select = question.type === QuestionTypes.Multi
        const is_text_input = question.type === QuestionTypes.Text
        const is_date_input = question.type === QuestionTypes.Date
        const is_image_input = question.type === QuestionTypes.Image
        const options = question.options.sort((a, b) => a.sort - b.sort)

        const answers = question.options
          .filter(op => op.value && op.value !== 'false')
          .map(op => op.name)

        let day = dayjs(question.answer)
        const answerDate = is_date_input
          ? day.isValid()
            ? day.toDate()
            : null
          : null

        const fileUrl = is_image_input ? question.answer : null

        return {
          ...question,
          options,
          is_invalid: false,
          is_single_select,
          is_multi_select,
          is_text_input,
          is_date_input,
          is_image_input,
          answers: is_multi_select ? answers : answers[0],
          answerDate,
          fileUrl
        }
      })

    const spot_answers = detail.spot_answers
      .sort((q1, q2) => q1.sequence - q2.sequence)
      .map(answer => {
        const spot_options = answer.question.spot_options.sort(
          (q1, q2) => q1.sequence - q2.sequence
        )
        return {
          ...answer,
          question: {
            ...answer.question,
            spot_options
          }
        }
      })

    return {
      ...detail,
      is_self,
      type_message: type,
      status_message: status,
      common_answers,
      spot_answers
    }
  }

  async function addDetail(orderNo: string, detail: OrderDetail) {
    try {
      detailSending.value = true
      const result = await axios.post(
        `api/order-details?orderNo=${orderNo}&culture=zh-TW`,
        detail
      )
      detailResult.value = true
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('addDetail failed', e.message)
      detailResult.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailSending.value = false
    }
  }

  async function updateDetail(id: number, detail: OrderDetail) {
    try {
      detailSending.value = true
      const result = await axios.put(`api/order-details/${id}`, detail)
      detailResult.value = true
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('updateDetail failed', e.message)
      detailResult.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailSending.value = false
    }
  }

  async function deleteDetail(id: number) {
    try {
      detailSending.value = true
      const result = await axios.delete(`api/order-details/${id}`)
      detailResult.value = true
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('deleteDetail failed', e.message)
      detailResult.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailSending.value = false
    }
  }

  async function uploadFile(file: File) {
    try {
      detailSending.value = true

      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      const formData = new FormData()
      formData.append('file', file)

      const api = 'api/order-details/answers/upload-file'
      const result = await axios.post(api, formData, { headers })
      detailResult.value = true
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('upload passport failed', e.message)
      detailResult.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailSending.value = false
    }
  }

  async function updateDetailAnswer(detail_id: number, data: object) {
    try {
      detailSending.value = true
      const api = `api/order-details/${detail_id}/answers`
      const result = await axios.put(api, data)
      detailResult.value = true
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('updateDetailAnswer failed', e.message)
      detailResult.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      detailSending.value = false
    }
  }

  return {
    detailLoading,
    detailSending,
    detailResult,
    detail,
    fetchDetail,
    addDetail,
    updateDetail,
    uploadFile,
    updateDetailAnswer,
    deleteDetail
  }
}
