





























































import {
  createComponent,
  reactive,
  ref,
  SetupContext
} from '@vue/composition-api'
import notifyService from '@/services/notify-service'
import FInput from '@/components/inputs/FInput.vue'
import FRadios from '@/components/inputs/FRadios.vue'
import { Dictionary } from 'vue-router/types/router'
import useCollaborateService from '@/composition/CollaborateService'

export default createComponent({
  name: 'Welcome',
  components: {
    FInput,
    FRadios
  },
  setup(props, context) {
    const { typeOptions, form, isSending, submit } = useForm(context)

    return {
      typeOptions,
      form,
      isSending,
      submit
    }
  }
})

function useForm(context: SetupContext) {
  const { root } = context
  const vat = getVat(root.$route.query)

  const typeOptions = ref([
    { name: '員工旅遊', value: 1 },
    { name: '商務差旅', value: 2 }
  ])

  const isSending = ref(false)
  const form = reactive({
    vat: vat,
    type: 0
  })

  const {
    collaborate_info,
    fetchCollaborateInfoByVat
  } = useCollaborateService()

  async function submit() {
    try {
      isSending.value = true
      await fetchCollaborateInfoByVat(form.vat!)
      localStorage.setItem('vat', form.vat!)

      if (form.type === 1) {
        root.$router
          .push({
            name: 'login',
            query: { c: collaborate_info.id.toString() }
          })
          .catch(() => {})
      } else if (form.type === 2) {
        root.$router.push({ name: 'firm-login' }).catch(() => {})
      }
    } catch (e) {
      notifyService.error('請確認公司統編是否輸入正確')
    } finally {
      isSending.value = false
    }
  }

  return {
    typeOptions,
    collaborate_info,
    form,
    isSending,
    submit
  }
}

function getVat(query: Dictionary<string | (string | null)[]>) {
  let vat: string | null = null
  if (query.v) {
    vat = query.v as string
    localStorage.setItem('vat', vat!)
  } else {
    const v = localStorage.getItem('vat')
    if (v) {
      vat = v
    }
  }

  return vat
}
