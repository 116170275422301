








import { createComponent } from '@vue/composition-api'
import Collaborate from '@/components/Collaborate.vue'
import Protection from '@/components/Protection.vue'

export default createComponent({
  name: 'Home',
  components: {
    Collaborate,
    Protection
  }
})
