
































































































import { createComponent, ref, computed, watch } from '@vue/composition-api'
import { Order, OrderDetail as OrderDetailType } from '@/types/order'
import useFormatService from '@/composition/FormatService'
import useImageService from '@/composition/ImageService'

export default createComponent({
  name: 'OrderCost',
  props: {
    order: {
      type: Object as () => Order,
      required: true
    },
    type: {
      type: String,
      require: true
    }
  },
  setup(props) {
    const { formatCurrency } = useFormatService()
    const { passportImageUrl } = useImageService()

    const details = ref<Array<OrderDetailType>>([])
    const totalCost = computed(() => {
      return details.value.reduce((total, detail) => {
        return total + detail.total_cost!
      }, 0)
    })

    if (props.type === 'formal') {
      details.value = props.order.details
    } else {
      details.value = props.order.temp_details
    }

    watch(
      () => props.order,
      () => {
        if (props.type === 'formal') {
          details.value = props.order.details
        } else {
          details.value = props.order.temp_details
        }
      }
    )

    return {
      details,
      totalCost,
      formatCurrency,
      passportImageUrl
    }
  }
})
