



















import { createComponent, ref, watch } from '@vue/composition-api'

export default createComponent({
  name: 'FInput',
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: String
    },
    showHighlight: {
      type: Boolean,
      default: true
    },
    showResultIcon: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const innerValue = ref<string>(null)

    function setInnerValue() {
      if (props.value) {
        innerValue.value = props.value
      } else {
        innerValue.value = null
      }
    }

    watch(() => props.value, setInnerValue)
    watch(innerValue, newVal => {
      context.emit('input', newVal)
    })

    setInnerValue()

    function getFieldStyle(errors: string[], valid: boolean) {
      if (!props.showHighlight) {
        return {}
      }

      if (!props.showResultIcon) {
        return { 'is-errors': !!errors[0] }
      }

      return {
        'is-danger': !!errors[0],
        'is-success': valid
      }
    }

    function getErrorMessage(errors: string[]) {
      return props.showErrors ? errors : null
    }

    return {
      innerValue,
      getFieldStyle,
      getErrorMessage
    }
  }
})
