







































































































































































































































































































































































































































import Vue from 'vue'
import { createComponent, onMounted, ref } from '@vue/composition-api'
import {
  CommonQuestionAnswer,
  OrderDetail,
  TravelSpot,
  TravelSpotQuestionAnswer
} from '@/types/order'
import { AgeTypes, QuestionOptionTypes, QuestionTypes } from '@/types/constant'
import useConstantService from '@/composition/ConstantService'
import useImageService from '@/composition/ImageService'
import useFormatService from '@/composition/FormatService'
import useOrderDetailService from '@/composition/OrderDetailService'
import OrderDetailInfoCard from '@/components/order/OrderDetailInfoCard.vue'
import notifyService from '@/services/notify-service'

export default createComponent({
  name: 'OrderDetailSignupForm',
  components: {
    OrderDetailInfoCard
  },
  props: {
    detail: {
      type: Object as () => OrderDetail,
      required: true
    },
    title: {
      type: String,
      default: '填寫參團報名表'
    },
    signupTitle: {
      type: String,
      default: '完成並離開'
    }
  },
  setup(props, ctx) {
    const { root, emit } = ctx
    const { detail } = props
    const { navbar } = useConstantService()
    const { productImageUrl } = useImageService()
    const { formatDate, formatDateWeek, formatCurrency } = useFormatService()
    const { uploadFile } = useOrderDetailService()

    const enum SignupStep {
      CommonQuestions,
      SpotQuestions,
      Final
    }

    const defaultCollapse = ref(false)
    const currentStep = ref(SignupStep.CommonQuestions)
    const currentSpotQuestionIndex = ref(0)

    onMounted(async () => {
      defaultCollapse.value = detail.open_spot!

      if (detail.common_answers.length === 0) {
        currentStep.value = SignupStep.SpotQuestions
      }
      if (detail.spot_answers.length === 0) {
        currentStep.value = SignupStep.Final
      }

      detail.spot_answers = detail.spot_answers.map(question => {
        return {
          ...question,
          origin_spot_id: question.selected_spot_id
        }
      })
    })

    function questionHint(answer: CommonQuestionAnswer) {
      return answer.is_multi_select ? '（可複選，至少選一個）' : ''
    }

    function selectedMultiAnswer(
      selected: string[],
      answer: CommonQuestionAnswer
    ) {
      updateMultiAnswer(answer)
    }

    function updateMultiAnswer(answer: CommonQuestionAnswer) {
      answer.options = answer.options.map(op => {
        if (op.type === QuestionOptionTypes.Text) {
          return op
        }

        return {
          ...op,
          value: answer.answers.includes(op.name) ? 'true' : 'false'
        }
      })
      answer.answer = answer.options
        .filter(op => answer.answers.includes(op.name))
        .map(op => (op.type === QuestionOptionTypes.Text ? op.value : op.name))
        .join(',')
    }

    function selectedAnswer(selected: string, answer: CommonQuestionAnswer) {
      updateSelectAnswer(answer)
    }

    function updateSelectAnswer(answer: CommonQuestionAnswer) {
      const selectedOption = answer.options.find(
        op => op.name === answer.answers
      )!

      const traveler_type = selectedOption.traveler_types.find(
        t => t.name == detail.traveler_type
      )!

      answer.options = answer.options.map(op => {
        if (op.type === QuestionOptionTypes.Text) {
          return op
        }

        return {
          ...op,
          value: op.name === answer.answers ? 'true' : 'false'
        }
      })
      answer.answer =
        selectedOption.type === QuestionOptionTypes.Text
          ? selectedOption.value
          : selectedOption.name

      answer.cost = traveler_type.price_variation

      detail.commons_cost = detail.common_answers.reduce((sum, answer) => {
        return sum + answer.cost
      }, 0)
    }

    function updateDateAnswer(answer: CommonQuestionAnswer) {
      answer.answer = formatDate(answer.answerDate)
      answer.cost = 0

      detail.commons_cost = detail.common_answers.reduce((sum, answer) => {
        return sum + answer.cost
      }, 0)
    }

    function updateFileAnswer(answer: CommonQuestionAnswer) {
      if (answer.file) {
        answer.fileUrl = URL.createObjectURL(answer.file)
      } else {
        answer.fileUrl = answer.answer
      }
      answer.cost = 0

      detail.commons_cost = detail.common_answers.reduce((sum, answer) => {
        return sum + answer.cost
      }, 0)
    }

    function updateAnswer(answer: CommonQuestionAnswer) {
      answer.cost = 0

      detail.commons_cost = detail.common_answers.reduce((sum, answer) => {
        return sum + answer.cost
      }, 0)
    }

    function onSelectSpotOption(
      spot_answer: TravelSpotQuestionAnswer,
      selected_id: number
    ) {
      const selected_spot = spot_answer.question.spot_options.find(
        op => op.id === selected_id
      )

      if (selected_spot) {
        spot_answer.selected_spot = selected_spot
        spot_answer.selected_spot_name = spot_answer.selected_spot.name

        if (detail.age_type === AgeTypes.Baby) {
          spot_answer.cost = selected_spot.baby_cost
        } else if (detail.age_type === AgeTypes.Child) {
          spot_answer.cost = selected_spot.child_cost
        } else {
          spot_answer.cost = selected_spot.adult_cost
        }

        detail.spots_cost = detail.spot_answers.reduce((sum, answer) => {
          return sum + answer.cost
        }, 0)
      }
    }

    function onNextQuestions() {
      if (!validationCommonQuestions()) {
        return
      }

      if (detail.spot_answers.length > 1) {
        currentStep.value = SignupStep.SpotQuestions
        currentSpotQuestionIndex.value = 0

        Vue.nextTick(() => {
          root.$scrollTo('#detail-sign-up-spot-questions', {
            offset: -navbar.height
          })
        })
      } else {
        currentStep.value = SignupStep.Final
      }
    }

    function onNextSpotQuestion() {
      if (!validationSpotQuestion(currentSpotQuestionIndex.value)) {
        return
      }

      currentSpotQuestionIndex.value++

      Vue.nextTick(() => {
        root.$scrollTo(`#spot-question-${currentSpotQuestionIndex.value}`, {
          offset: -navbar.height
        })
      })

      if (currentSpotQuestionIndex.value >= detail.spot_answers.length - 1) {
        currentStep.value = SignupStep.Final
      }
    }

    async function onSignup() {
      if (!validationCommonQuestions()) {
        return
      }
      if (!validationSpotQuestions()) {
        return
      }

      emit('sending', true)

      const common_answers = []

      for (let i = 0; i < detail.common_answers.length; i++) {
        const question = detail.common_answers[i]

        if (question.is_image_input && question.file) {
          const maxSize = 5 * 1024 * 1024
          if (question.file.size > maxSize) {
            notifyService.error(`${question.title}檔案不可超過5MB`)
            emit('sending', false)
            return
          }
        }
      }

      for (let i = 0; i < detail.common_answers.length; i++) {
        const question = detail.common_answers[i]

        const options = question.options.map(option => {
          return {
            name: option.name,
            sort: option.sort,
            type: option.type,
            value: option.value
          }
        })

        let answer = question.answer

        if (question.is_image_input && question.file) {
          try {
            const result = await uploadFile(question.file)

            answer = result.data.url
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('s3', e)
            notifyService.error(`${question.title}檔案上傳失敗，請稍後再試！`)
            emit('sending', false)
            return
          }
        }

        common_answers.push({
          id: question.id,
          type: question.type,
          answer,
          options
        })
      }

      const spot_answers = detail.spot_answers.map(question => {
        return {
          id: question.id,
          spot_options: question.spot_options,
          selected_spot_id: question.selected_spot_id,
          cost: question.cost
        }
      })

      const data = { common_answers, spot_answers }

      emit('signup', data)
      emit('sending', false)
    }

    function validationCommonQuestions() {
      let invalidIndex = -1

      detail.common_answers = detail.common_answers.map((question, index) => {
        let is_invalid = false

        if (question.type === QuestionTypes.Multi) {
          if (question.answers.length === 0) {
            is_invalid = true
          } else {
            const emptyAnswers = question.options
              .filter(op => question.answers.includes(op.name))
              .map(op =>
                op.type === QuestionOptionTypes.Text ? op.value : op.name
              )
              .filter(a => !a)

            is_invalid = emptyAnswers.length > 0
          }
        } else if (question.type === QuestionTypes.Select) {
          if (!question.answer || !question.answers) {
            is_invalid = true
          } else {
            const option = question.options.find(
              op => op.name === question.answers
            )!

            if (option.type === QuestionOptionTypes.Text) {
              is_invalid = question.answer !== option.value
            } else {
              is_invalid = question.answer !== option.name
            }
          }
        } else if (question.type === QuestionTypes.Text) {
          if (!question.answer) {
            is_invalid = true
          }
        } else if (question.type === QuestionTypes.Date) {
          if (!question.answer) {
            is_invalid = true
          }
        } else if (question.type === QuestionTypes.Image) {
          if (!question.fileUrl) {
            is_invalid = true
          }
        }

        if (invalidIndex == -1 && is_invalid) {
          invalidIndex = index
        }

        return {
          ...question,
          is_invalid
        }
      })

      if (invalidIndex != -1) {
        root.$scrollTo(`#common-question-${invalidIndex}`, {
          offset: -navbar.height
        })

        return false
      }

      return true
    }

    function validationSpotQuestions() {
      for (let index = 0; index < detail.spot_answers.length; index++) {
        if (!validationSpotQuestion(index)) {
          return false
        }
      }

      return true
    }

    function validationSpotQuestion(index: number) {
      const question = detail.spot_answers[index]

      if (!question.selected_spot_id) {
        root.$scrollTo(`#spot-question-${index}`, {
          offset: -navbar.height
        })

        return false
      }

      return true
    }

    function costTip(option: TravelSpot, type: AgeTypes) {
      let prefix
      let cost
      let tip
      if (type === AgeTypes.Baby) {
        prefix = '嬰兒'
        cost = formatCurrency(option.baby_cost)
        tip = option.baby_tip && `(${option.baby_tip})`
      } else if (type === AgeTypes.Child) {
        prefix = '孩童'
        cost = formatCurrency(option.child_cost)
        tip = option.child_tip && `(${option.child_tip})`
      } else {
        prefix = '成人'
        cost = formatCurrency(option.adult_cost)
        tip = option.adult_tip && `(${option.adult_tip})`
      }

      return `${prefix} NT$ ${cost} ${tip}`
    }

    return {
      currentStep,
      currentSpotQuestionIndex,
      defaultCollapse,
      questionHint,
      selectedMultiAnswer,
      updateMultiAnswer,
      selectedAnswer,
      updateSelectAnswer,
      updateDateAnswer,
      updateFileAnswer,
      updateAnswer,
      onSelectSpotOption,
      onNextQuestions,
      onNextSpotQuestion,
      onSignup,
      formatDate,
      formatDateWeek,
      productImageUrl,
      costTip
    }
  }
})
