import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import * as types from './mutationTypes'
import { APP_SET_AGREE_PROTECTION } from "./mutationTypes";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showTutorialSignup: Cookies.get('show-tutorial-signup') == 'true',
    showTutorialPassengerSignup:
      Cookies.get('show-tutorial-passenger-signup') == 'true',
    agreeProtection: Cookies.get('is-agree-protection') == 'true'
  },
  mutations: {
    [types.APP_SET_SHOW_TUTORIAL](state, isEnable) {
      state.showTutorialSignup = isEnable
      state.showTutorialPassengerSignup = isEnable
      Cookies.set('show-tutorial-signup', isEnable.toString())
      Cookies.set('show-tutorial-passenger-signup', isEnable.toString())
    },
    [types.APP_SET_SHOW_TUTORIAL_SIGNUP](state, isEnable) {
      state.showTutorialSignup = isEnable
      Cookies.set('show-tutorial-signup', isEnable.toString())
    },
    [types.APP_SET_SHOW_TUTORIAL_PASSENGER_SIGNUP](state, isEnable) {
      state.showTutorialPassengerSignup = isEnable
      Cookies.set('show-tutorial-passenger-signup', isEnable.toString())
    },
    [types.APP_SET_AGREE_PROTECTION](state, isAgree) {
      state.agreeProtection = isAgree
      Cookies.set('is-agree-protection', isAgree.toString())
    }
  },
  actions: {
    showTutorial({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL, true)
    },
    hideTutorial({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL, false)
    },
    showTutorialSignup({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL_SIGNUP, true)
    },
    hideTutorialSignup({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL_SIGNUP, false)
    },
    showTutorialPassengerSignup({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL_PASSENGER_SIGNUP, true)
    },
    hideTutorialPassengerSignup({ commit }) {
      commit(types.APP_SET_SHOW_TUTORIAL_PASSENGER_SIGNUP, false)
    },
    agreeProtection({ commit }) {
      commit(types.APP_SET_AGREE_PROTECTION, true)
    }
  },
  modules: {},
  getters: {
    isShowTutorialSignup: state => state.showTutorialSignup,
    isShowTutorialPassengerSignup: state => state.showTutorialPassengerSignup,
    isAgreeProtection: state => state.agreeProtection
  }
})
;(Vue as any).store = store

export const useStore = () => store

export default store
