<template functional>
  <div class="single-field">
    <div class="single-field-name">{{ props.label }}</div>
    <div
      class="single-field-value"
      :class="{ 'no-value': props.data == null || props.data === '' }"
    >
      <p v-if="props.type === 4">
        <img :src="props.data" :alt="props.label" style="height: 160px" />
      </p>
      <p v-else>
        {{ props.data }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetailField',
  props: {
    label: String,
    data: [String, Number],
    type: Number
  }
}
</script>
