import Vue, { VueConstructor } from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TravelGroup from '../views/TravelGroup.vue'
import TravelGroupConfirm from '../views/TravelGroupConfirm.vue'
import OrderDetailSignupView from '../views/OrderDetailSignupView.vue'
import Welcome from '@/views/Welcome.vue'
import Login from '../views/Login.vue'
import FirmLogin from '@/views/FirmLogin.vue'
import Firm from '@/views/Firm.vue'
import FirmComplete from "@/views/FirmComplete.vue";

Vue.use(VueRouter)

function loadView(view: string) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true
    },
    component: Home as VueConstructor<Vue>
  },
  {
    path: '/travels/:code',
    name: 'travel-group',
    meta: {
      auth: true
    },
    component: TravelGroup as VueConstructor<Vue>
  },
  {
    path: '/travels/:code/confirm',
    name: 'travel-group-confirm',
    meta: {
      auth: true
    },
    component: TravelGroupConfirm as VueConstructor<Vue>
  },
  {
    path: '/detail/:id/signup',
    name: 'order-detail-signup',
    meta: {
      auth: true
    },
    component: OrderDetailSignupView as VueConstructor<Vue>
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome as VueConstructor<Vue>
  },
  {
    path: '/login',
    name: 'login',
    component: Login as VueConstructor<Vue>
  },
  {
    path: '/firm/login',
    name: 'firm-login',
    component: FirmLogin as VueConstructor<Vue>
  },
  {
    path: '/firm',
    name: 'firm',
    component: Firm as VueConstructor<Vue>
  },
  {
    path: '/firm-complete',
    name: 'firm-complete',
    component: FirmComplete as VueConstructor<Vue>
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
;(Vue as any).router = router

export default router
