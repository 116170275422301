









import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'Footer',
  props: {
    invertColor: Boolean
  }
})
