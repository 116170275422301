
















































































































import { createComponent, ref, onMounted, computed } from '@vue/composition-api'
import { OrderStatus } from '@/types/constant'
import useFormatService from '@/composition/FormatService'
import useOrderService from '@/composition/OrderService'
import notifyService from '@/services/notify-service'
import OrderDetailInfoCard from '@/components/order/OrderDetailInfoCard.vue'
import OrderCost from '@/components/order/OrderCost.vue'

export default createComponent({
  name: 'TravelGroupConfirm',
  components: {
    OrderDetailInfoCard,
    OrderCost
  },
  setup(props, ctx) {
    const { root } = ctx
    const { formatDate, formatWeek, formatDateWeek } = useFormatService()
    const {
      orderSending,
      orderLoading,
      orderResult,
      order,
      fetchOrder,
      sendOrder
    } = useOrderService(true)
    const showTotalFee = computed(() => {
      return (
        order.value.travel_group.activity &&
        order.value.travel_group.activity.show_total_fee
      )
    })

    const showAdvance = ref(true)

    onMounted(async () => {
      await fetchOrder(root.$route.params.code)

      if (!orderResult.value) {
        return notifyService.alert(
          '無法載入資料，請聯絡服務人員！',
          3000,
          () => {
            root.$router.push({ name: 'login' }).catch(() => {})
          }
        )
      }

      showAdvance.value = order.value.travel_group!.activity!.type === 0

      let message: string = ''
      if (order.value.travel_group.is_expired) {
        message = '報名已截止！'
      } else if (order.value.status < OrderStatus.Completed) {
        if (order.value.travel_group.is_full) {
          message = '企業團名額已滿！'
        } else if (order.value.user_activity.is_signup) {
          message = '只可報名一個企業團！'
        }
      }

      if (message) {
        return notifyService.alert(message, 3000, () => {
          root.$router.push({ name: 'login' }).catch(() => {})
        })
      }
    })

    function onCancel() {
      root.$router.push({
        name: 'travel-group',
        params: { code: order.value.group_code }
      })
    }

    async function onConfirm() {
      const result = await sendOrder(order.value.order_no)
      if (result.success) {
        notifyService.alert('報名成功，已將資料寄到您的電子信箱', 3000, () => {
          if (order.value.travel_group.activity!.type === 0) {
            root.$router.push({
              name: 'travel-group',
              params: { code: order.value.group_code }
            })
          } else {
            root.$router.push({ name: 'login' }).catch(() => {})
          }
        })
      } else {
        notifyService.alert(result.message || '報名失敗，請稍後再試', 5000)
      }
    }

    return {
      orderSending,
      orderLoading,
      orderResult,
      order,
      fetchOrder,
      showAdvance,
      showTotalFee,
      onCancel,
      onConfirm,
      formatDateWeek,
      formatDate,
      formatWeek
    }
  }
})
