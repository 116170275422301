<template functional>
  <div class="single-field">
    <div class="single-field-name">{{ props.label }}</div>
    <div
      class="single-field-value"
      :class="{ 'not-upload': props.data == null }"
    >
      {{ props.data }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetailLinkField',
  props: {
    label: String,
    data: String
  }
}
</script>
