import Vue from 'vue'
import vuexI18n from 'vuex-i18n'
import store from '@/store'
import en from '@/locales/en'
import tw from '@/locales/zh-Hant'
import enValidation from 'vee-validate/dist/locale/en.json'
import twValidation from 'vee-validate/dist/locale/zh_TW.json'

Vue.use(vuexI18n.plugin, store)

Vue.i18n.add('en', { ...en, validation: enValidation.messages })
Vue.i18n.add('zh-Hant', { ...tw, validation: twValidation.messages })

// set the start locale to use
Vue.i18n.fallback('en')
Vue.i18n.set('zh-Hant')
