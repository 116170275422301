import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.use(VueCompositionApi)

declare module '@vue/composition-api/dist/component/component' {
  interface SetupContext {
    readonly refs: {
      [key: string]: Vue | Element | Vue[] | Element[]
      observer: InstanceType<typeof ValidationObserver>
      provider: InstanceType<typeof ValidationProvider>
    }
  }
}
