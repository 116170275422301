
























import { createComponent, onBeforeMount } from '@vue/composition-api'
import useCollaborateService from '@/composition/CollaborateService'
import notifyService from '@/services/notify-service'

export default createComponent({
  name: 'FirmComplete',
  setup(props, context) {
    const { root } = context

    const {
      collaborate_info,
      fetchFirmInfo,
      getWithExpiry
    } = useCollaborateService()

    onBeforeMount(async () => {
      const token = getWithExpiry('FirmAuthToken')

      if (!token) {
        notifyService.alert('登入憑證已過期', 5000, () => {
          root.$router.push({ name: 'firm-login' }).catch(() => {})
        })
      } else {
        const result = await fetchFirmInfo(token)

        if (!result) {
          notifyService.alert('登入憑證已過期', 5000, () => {
            root.$router.push({ name: 'firm-login' }).catch(() => {})
          })
        }
      }
    })

    return {
      collaborate_info
    }
  }
})
