import { reactive, ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import useImageService from '@/composition/ImageService'

export default function useCollaborateService() {
  const { collaborateImageUrl } = useImageService()

  const uploading = ref(false)
  const collaborate_info = reactive({
    id: 1,
    name: '',
    vat: '',
    firm_feature: [] as string[],
    logo: '',
    service_tel: '',
    contacts: []
  })

  async function fetchCollaborateInfo(id: number) {
    try {
      const result = await axios.get(`api/collaborates/${id}`)
      const data = result.data.data

      fillCollaborateInfo(data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('fetchCollaborateInfo failed: ', e.message)
    }
  }

  async function fetchCollaborateInfoByVat(vat: string) {
    try {
      const result = await axios.get(`api/collaborates/vat/${vat}`)
      const data = result.data.data

      fillCollaborateInfo(data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('fetchCollaborateInfoByVat failed: ', e.message)
      throw e
    }
  }

  async function requestOneTimePassword(email: string) {
    try {
      await axios.post(`api/collaborates/otp`, { email })

      return true
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('getOneTimePassword failed: ', e.message)
      return false
    }
  }

  async function firmLogin(vat: string, email: string, code: string) {
    try {
      const response = await axios.post(`api/collaborates/firm/login`, {
        vat,
        email,
        code
      })

      return response.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('firmLogin failed: ', e.message)
      throw e
    }
  }

  async function fetchFirmInfo(token: string) {
    try {
      const result = await axios.post(`api/collaborates/firm/info`, { token })
      const data = result.data.data

      fillCollaborateInfo(data)

      return true
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('fetchFirmInfo failed: ', e.message)
      return false
    }
  }

  function fillCollaborateInfo(data: any) {
    collaborate_info.id = data.id
    collaborate_info.name = data.name
    collaborate_info.vat = data.vat
    collaborate_info.firm_feature = JSON.parse(data.firm_feature)
    collaborate_info.logo = collaborateImageUrl(data.logo)
    collaborate_info.service_tel = data.service_tel
    collaborate_info.contacts = data.contacts
  }

  function setWithExpiry(key: string, value: string, ttl: number) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  function getWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }

    return item.value
  }

  async function createFirmOrder(data: any) {
    try {
      const response = await axios.post(`api/collaborates/firm/order`, data)

      return response.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('firmLogin failed: ', e.message)
      throw e
    }
  }

  async function uploadFile(file: File) {
    try {
      uploading.value = true

      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      const formData = new FormData()
      formData.append('file', file)

      const api = 'api/collaborates/firm/passport/upload-file'
      const result = await axios.post(api, formData, { headers })
      return result.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('upload passport failed', e.message)
      uploading.value = false
      return {
        success: false,
        message: e.message
      }
    } finally {
      uploading.value = false
    }
  }

  return {
    collaborate_info,
    fetchCollaborateInfo,
    fetchCollaborateInfoByVat,
    requestOneTimePassword,
    firmLogin,
    fetchFirmInfo,
    setWithExpiry,
    getWithExpiry,
    createFirmOrder,
    uploading,
    uploadFile
  }
}
