// 使用常量替代 Mutation 事件類型
// 命名規則: [module]_[mutation name]

/* app */

export const APP_SET_SHOW_TUTORIAL = 'APP_SET_SHOW_TUTORIAL'
export const APP_SET_SHOW_TUTORIAL_SIGNUP = 'APP_SET_SHOW_TUTORIAL_SIGNUP'
export const APP_SET_SHOW_TUTORIAL_PASSENGER_SIGNUP =
  'APP_SET_SHOW_TUTORIAL_PASSENGER_SIGNUP'

export const APP_SET_AGREE_PROTECTION = 'APP_SET_AGREE_PROTECTION'
