export const fields = {
  username: '帳號',
  password: '密碼',

  name: '中文姓名',
  english_name: '英文姓名',
  identity_number: '身分證字號',
  nationality: '國籍',
  gender: '性別',
  email: '電子信箱',
  emergency_contact: '緊急聯絡人',
  emergency_phone: '緊急聯絡人電話',
  relationship: '關係',
  date_of_birth: '出生日期',
  passport_number: '護照號碼',
  passport_expire: '護照效期',
  mobile_phone: '本人手機',
  tel: '公司電話',
  home_address: '住家地址',
  remark: '備註'
}

export const message = {
  fantasy_tours: '上順旅行社',
  service_contact: '請聯絡貴公司服務人員',
  service_contact_tel: '請來電 {tel} 由專人為您服務'
}

export default {
  fields,
  message
}
