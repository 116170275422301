export const enum OrderStatus {
  Canceled = -1,
  None,
  Filling,
  Completed,
  // Modified,
  Specified = 5,
  Opened = 6,
  Edited = 7
}

export const enum OrderDetailStatus {
  New, // 新增
  Signed // 已報名
}

export const enum AgeTypes {
  Adult,
  Child,
  Baby
}

export const ageTypeNames = ['成人', '孩童', '嬰兒']

export const enum BedTypes {
  Adult,
  ChildBed,
  ChildNoBed,
  Baby,
  AddBed
}

export const BedNames = [
  '成人(佔床)',
  '孩童(佔床)',
  '孩童(不佔床)',
  '嬰兒',
  '加床'
]

export const enum QuestionTypes {
  Select,
  Multi,
  Text,
  Date,
  Image
}

export const enum QuestionOptionTypes {
  Text = 'Text',
  Option = 'Option'
}

export const enum MemberTypes {
  TourLeader = 'A', // 領隊導遊
  Guests = 'B', // 客人
  Enterprise = 'C', // 公司企業
  Cooperation = 'D' // 異業合作
}
