
































































































































import { createComponent, computed } from '@vue/composition-api'
import useImageService from '@/composition/ImageService'

export default createComponent({
  name: 'Collaborate',
  setup(props, { root }) {
    const { collaborateImageUrl } = useImageService()

    const user = computed(() => {
      return root.$auth.user() || {}
    })
    const userName = computed(() => {
      return user.value.display_name
    })
    const collaborate = computed(() => {
      return user.value.collaborate
    })
    const logo = computed(() => {
      return collaborateImageUrl(collaborate.value.logo)
    })

    return {
      user,
      userName,
      collaborate,
      logo
    }
  }
})
