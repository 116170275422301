














































































































































































































import { createComponent, ref, computed, onMounted } from '@vue/composition-api'
import Order from '@/components/order/Order.vue'
import useFormatService from '@/composition/FormatService'
import useImageService from '@/composition/ImageService'
import useTravelGroupService from '@/composition/TravelGroupService'

export default createComponent({
  name: 'TravelGroup',
  components: {
    Order
  },
  setup(props, ctx) {
    const { root } = ctx
    const { fantasyUrl } = useImageService()
    const { formatDate, formatWeek } = useFormatService()
    const {
      groupLoading,
      groupResult,
      fetchTravelGroupInfo,
      group
    } = useTravelGroupService()
    const orderStatus = ref('-')

    onMounted(async () => {
      await fetchTravelGroupInfo(root.$route.params.code)

      if (!groupResult.value) {
        await root.$router
          .replace({
            name: root.$route.name || 'home',
            query: { c: root.$route.query.c }
          })
          .catch(() => {})

        return
      }
    })

    function setOrderStatus(status: string) {
      orderStatus.value = status
    }

    const fileLinks = computed(() => {
      const files: { link: string; name: string }[][] = [[], []]

      const activity = group.value.activity!

      files[0].push({
        name: '詳細行程',
        link: group.value.tour_url
      })

      const links: { link: string; name: string }[] = []
      if (activity.show_doc_file) {
        links.push({
          name: '行程下載',
          link: group.value.doc_url
        })
      }
      if (activity.show_orientation_url) {
        links.push({
          name: '線上說明會',
          link: group.value.orientation_url
        })
      }
      if (activity.show_menu_url) {
        links.push({
          name: '說明手冊下載',
          link: group.value.menu_url
        })
      }

      if (links.length === 1) {
        files[1].push(links[0])
      } else if (links.length === 2) {
        files[1].push(links[0])
        files[1].push(links[1])
      } else if (links.length === 3) {
        files[0].push(links[0])
        files[1].push(links[1])
        files[1].push(links[2])
      }

      return files
    })

    const showOrderStatus = ref(false)

    const showSignupDeadline = ref(
      group.value.registration_end_date !== group.value.signup_deadline
    )

    return {
      groupLoading,
      groupResult,
      group,
      orderStatus,
      fileLinks,
      setOrderStatus,
      showOrderStatus,
      showSignupDeadline,
      fantasyUrl,
      formatDate,
      formatWeek
    }
  }
})
