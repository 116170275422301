import { ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { Order, OrderDetail } from '@/types/order'
import { OrderDetailStatus, OrderStatus } from '@/types/constant'

export default function useOrderService(isConfirm: boolean) {
  const orderLoading = ref(true)
  const orderSending = ref(false)
  const orderResult = ref(false)
  const order = ref<Order>({
    order_no: null,
    status: 0,
    status_message: '-',
    quota: -1,
    quota_free: -1,
    quota_pay: -1,
    free_quota: -1,
    group_name: '-',
    is_signup: false,
    travel_group: {},
    user_activity: {},
    temp_details: []
  })

  async function fetchOrder(code: string) {
    try {
      const api = isConfirm
        ? `api/orders/${code}/confirm`
        : `api/orders/${code}`
      orderLoading.value = true
      const result = await axios.get(api)
      updateOrder(result.data.data)
      orderResult.value = true
      return result.data
    } catch (e) {
      orderResult.value = false
      // eslint-disable-next-line no-console
      console.error('fetchTravelGroupOrder failed: ', e.message)
      return {
        success: false,
        message: e.message
      }
    } finally {
      orderLoading.value = false
    }
  }

  function updateOrder(data: Order) {
    let status_message = '未報名'
    if (data.status == OrderStatus.Completed) {
      if (data.is_modified) {
        status_message = '報名完成 變更尚未送出'
      } else if (data.is_complete_filled) {
        status_message = '報名完成'
      } else {
        if (data.travel_group && data.travel_group.activity) {
          if (data.travel_group.activity.type === 0) {
            status_message = '報名完成 資料不全'
          } else {
            status_message = '報名完成'
          }
        } else {
          status_message = '報名完成 資料不全'
        }
      }
    } else if (data.status == OrderStatus.Specified) {
      status_message = '未報名'
    } else if (data.status == OrderStatus.Opened) {
      status_message = '未報名'
    } else if (data.status == OrderStatus.Edited) {
      status_message = '未報名'
    }

    const details = data.details.map(normalizeDetail)
    const temp_details = data.temp_details
      .filter(d => !d.mark_remove)
      .map(normalizeDetail)
    const max_quota = data.user_activity.max_quota
    const free_quota = data.user_activity.free_quota
    const pay_quota = max_quota - free_quota
    const quota = temp_details.length
    const quota_free = temp_details.filter(d => d.is_free).length
    const free_count = free_quota - quota_free
    const quota_pay = quota - quota_free

    order.value.user_activity = data.user_activity
    order.value.travel_group = data.travel_group
    order.value.order_no = data.order_no
    order.value.status = data.status
    order.value.status_message = status_message
    order.value.user_id = data.user_id
    order.value.user_account = data.user_account
    order.value.user_name = data.user_name
    order.value.group_code = data.group_code
    order.value.group_name = data.group_name
    order.value.departure_date = data.departure_date
    order.value.subsidy = data.subsidy
    order.value.total_fee = data.total_fee
    order.value.total_cost = data.total_cost
    order.value.is_complete_filled = data.is_complete_filled
    order.value.is_modified = data.is_modified
    order.value.completed_time = data.completed_time
    order.value.is_modify_expired = data.is_modify_expired
    order.value.temp_details = temp_details
    order.value.details = details
    order.value.details_count = data.details_count
    order.value.details_free_count = data.details_free_count
    order.value.is_signup = data.is_signup
    order.value.max_quota = max_quota
    order.value.free_quota = free_quota
    order.value.pay_quota = pay_quota
    order.value.quota = quota
    order.value.quota_free = quota_free
    order.value.quota_pay = quota_pay
    order.value.free_count = free_count
  }

  function normalizeDetail(detail: OrderDetail, index: number) {
    const is_self = index === 0
    const type = is_self ? '本人' : detail.is_free ? '免攜' : '自費'

    const status =
      detail.status == OrderDetailStatus.New
        ? '(未報名)'
        : detail.is_modified
        ? '(修改未送出)'
        : '(完成報名)'

    return {
      ...detail,
      is_self,
      type_message: type,
      status_message: status
    }
  }

  async function sendOrder(orderNo: string) {
    try {
      orderSending.value = true
      const result = await axios.put(`api/orders/${orderNo}`)
      orderResult.value = true
      return result.data
    } catch (e) {
      orderResult.value = false
      // eslint-disable-next-line no-console
      console.error('sendOrder failed: ', e.message)
      return {
        success: false,
        message: e.message
      }
    } finally {
      orderSending.value = false
    }
  }

  async function deleteOrder(orderNo: string) {
    try {
      const result = await axios.delete(`api/orders/${orderNo}`)
      return result.data
    } catch (e) {
      return {
        success: false,
        message: e.message
      }
    }
  }

  return {
    orderSending,
    orderLoading,
    orderResult,
    order,
    fetchOrder,
    sendOrder,
    updateOrder,
    deleteOrder
  }
}
